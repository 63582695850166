/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";
import NoSiteSelected from "./NoSiteSelected";
import Select from "react-select";
import {
  updateSelectedSite,
  updateCurrentRoleSelected,
  updateCurrentRoleList,
  updateAspiredRoleSelected,
} from "../../store/actions";
import Loader from "../../utilities/Loader/Loader";
import axios from "axios";
import { baseURL } from "../../utilities/getFromApi";
import ErrorPopup from "../../utilities/ErrorPopup/ErrorPopup";
import SiteSelected from "./SiteSelected";
import RolePage from "../../utilities/RolePage";

const CareerPathways = () => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state);
  const [error, setError] = useState(null);
  const [modal, isModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedActiveSite, setSelectedActiveSite] = useState(
    storeData.storeUserActiveSiteSelected
  );
  const [rolePage, showRolePage] = useState(false);
  const [siteOptions, setSiteOptions] = useState([]);
  const handleSiteChange = (e) => {
    setSelectedActiveSite(e);
    dispatch(updateSelectedSite(e));
    dispatch(updateCurrentRoleSelected(null));
    dispatch(updateCurrentRoleList([]));
    dispatch(updateAspiredRoleSelected(null));
  };
  const customStyles = {
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        transition: "background-color 0.3s,color 0.3s",
        padding: "9px",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
          padding: "9px",
        },
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? "black" : "white",
        "&:hover": {
          backgroundColor: state.isSelected ? "black" : "#efefef",
          cursor: "pointer",
        },
      };
    },

    indicatorSeparator: (base) => ({
      ...base,
      marginTop: "0px",
      marginBottom: "0px",
    }),
    control: (base) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };

  useEffect(() => {
    setLoader(true);
    const body = storeData.storeOperatingUnitData.map((unit) => unit.value);
    axios
      .post(
        `${baseURL}api/get_site_name`,
        { selected_ou_names: body },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        const siteNameData = data.data
          .map((val) => {
            return {
              label: val.siteName,
              value: val.siteName,
              status: val.show === "Active" ? "Active" : "Comingsoon",
              stateName: val.state === "EMPTY" ? ", " : `, ${val.state}, `,
              ...val,
            };
          })
          .filter((option) => option.show === "Active");
        setSiteOptions(siteNameData);
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        isModalOpen(true);
        setLoader(false);
      });
  }, []);
  const handleRolePage = (flag) => {
    showRolePage(flag);
  };
  return (
    <div className={s.container}>
      {rolePage ? (
        <RolePage
          handleRolePage={handleRolePage}
          selectedActiveSite={selectedActiveSite}
        />
      ) : (
        <>
          <Loader state={loader} />
          {modal && (
            <ErrorPopup error={error} closeBtn={() => isModalOpen(false)} />
          )}
          <div className={s.headerContainer}>
            <div className={s.header}>Career Pathways</div>
            <div className={s.switchSite}>
              <div className={s.switchSiteLabel}>
                <div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.1"
                      cx="7.46343"
                      cy="7.46343"
                      r="7.46343"
                      fill="#E1242A"
                    />
                    <circle
                      cx="3.73171"
                      cy="3.73171"
                      r="3.73171"
                      transform="matrix(-1 0 0 1 11.1953 3.72998)"
                      fill="#E1242A"
                    />
                  </svg>
                </div>
                <div>Site</div>
              </div>
              <div className={s.switchSiteDropdown}>
                <Select
                  options={siteOptions}
                  title={"Site list"}
                  className={s.dropdownWrap}
                  name={"name"}
                  onChange={(val) => {
                    handleSiteChange(val);
                  }}
                  menuShouldScrollIntoView={true}
                  components={
                    {
                      // IndicatorSeparator,
                    }
                  }
                  isSearchable={false}
                  styles={customStyles}
                  allowSelectAll={false}
                  value={selectedActiveSite}
                />
              </div>
            </div>
          </div>
          <div className={s.bodyContainer}>
            {selectedActiveSite && selectedActiveSite.show === "Active" ? (
              <SiteSelected
                selectedActiveSite={selectedActiveSite}
                handleRolePage={handleRolePage}
              />
            ) : (
              <NoSiteSelected />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default CareerPathways;
