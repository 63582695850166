/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import s from "./style.module.css";
import { updateRoleSelected } from "../../../../store/actions";
// const MARGIN = { top: 10, right: 10, bottom: 30, left: 30 };

// type RolePathChartProps = {
//   width: number;
//   height: number;
//   data: { x: string; y: string; value: number }[];
// };
const colorArray = [
  { name: "Quality Small Molecules", color: "#9B72B0" },
  { name: "Quality Biologics", color: "#9B72B0" },
  { name: "Quality", color: "#9B72B0" },
  { name: "Environmental, Health & Safety", color: "#ABB436" },
  { name: "Data, Digital & Technology", color: "#EA5532" },
  { name: "Business Excellence", color: "#DA5283" },
  { name: "Engineering", color: "#51B1BF" },
  { name: "Manufacturing Small Molecule", color: "#EBA800" },
  { name: "Manufacturing Biologics", color: "#EBA800" },
  { name: "Manufacturing", color: "#44C28E" },
  { name: "Manufacturing Sciences", color: "#EFB933" },
  { name: "Supply Chain", color: "#4C9BCF" },
];

export const RolePathChart = ({
  width,
  height,
  data,
  paths,
  handleRolePage,
}) => {
  const dispatch = useDispatch();
  const [selectPathColor, setSelectPathColor] = useState(null);
  const [pathIndex, setPathIndex] = useState(null);

  // const boundsWidth = width - MARGIN.right - MARGIN.left;
  // const boundsHeight = height - MARGIN.top - MARGIN.bottom;
  const boundsWidth = width;
  const boundsHeight = height;

  const customSort = (a, b) => {
    const priorityDepartments = [
      "Global Manufacturing & Supply",
      "Global Quality",
    ];
    const apriority = priorityDepartments.includes(a);
    const bpriority = priorityDepartments.includes(b);

    if (apriority && bpriority) {
      return a.localeCompare(b);
    }
    if (apriority) return -1;
    if (bpriority) return 1;
    return a.localeCompare(b);
  };

  // groups
  const allYGroups = useMemo(
    () => [...new Set(data.map((d) => d.department))],
    [data]
  ).sort(customSort);
  const allXGroups = useMemo(
    () => [...new Set(data.map((d) => d.career_band))],
    [data]
  );

  // x and y scales
  const xScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([0, boundsWidth])
      .domain(allXGroups.sort())
      .padding(0.01);
  }, [data, width]);

  const yScale = useMemo(() => {
    return d3
      .scaleBand()
      .range([boundsHeight, 0])
      .domain(allYGroups.reverse())
      .padding(0.01);
  }, [data, height]);

  const [min, max] = d3.extent(data.map((d) => d.business_title));

  if (!min || !max) {
    return null;
  }

  // Build the line
  var linePath = [];
  const lineBuilder = d3
    .line()
    .curve(d3.curveBumpX)
    .x((d) => xScale(d.career_band) + 100)
    .y((d) => yScale(d.department));
  // eslint-disable-next-line
  paths.map((p) => {
    linePath.push(lineBuilder(p.path_elements));
  });
  if (!linePath) {
    return null;
  }
  const updatedData = data.map((d, i) => {
    return {
      ypoint:
        i % 2 === 0 ? yScale(d.department) - 25 : yScale(d.department) + 20,
      xpoint: xScale(d.career_band) + 100,
      ...d,
    };
  });
  const prevOcc = {};
  updatedData.forEach((d, i) => {
    const { career_band, ypoint } = d;
    const key = `${career_band}-${ypoint}`;

    if (prevOcc[key]) {
      d.ypoint += 12;
      prevOcc[key] += 1;
    } else {
      prevOcc[key] = 1;
    }
  });
  const prevOcc2 = {};
  updatedData.forEach((d, i) => {
    const { career_band, function: func, department } = d;
    const key = `${career_band}-${department}-${func}`;
    if (prevOcc2[key]) {
      d.xpoint = d.xpoint + prevOcc2[key] * 15;
      prevOcc2[key] += 1;
    } else {
      prevOcc2[key] = 1;
    }
  });

  // Build the rectangles
  const allRects = updatedData.map((d, i) => {
    return (
      <g key={i}>
        <circle
          key={i}
          r={7} // radius
          cx={d.xpoint} // position on the X axis
          cy={yScale(d.department)} // on the Y axis
          opacity={1}
          stroke="rgba(192, 192, 192, 1)"
          fill="rgba(192, 192, 192, 1)"
          // fillOpacity={0.2}
          strokeWidth={1}
        />
        <text
          x={xScale(d.career_band) + 90}
          // y={
          //   i % 2 === 0 ? yScale(d.department) + 20 : yScale(d.department) - 20
          // }
          y={d.ypoint}
          fontSize={11}
          color="red"
          fontWeight={600}
          className={s.selectRole}
          dominantBaseline={"middle"} // vertical alignment
          onClick={(e) => {
            e.preventDefault();
            handleRolePage(true);
            dispatch(updateRoleSelected(d.business_title));
          }}
        >
          {d.business_title}
        </text>
      </g>
    );
  });

  const allRects1 =
    pathIndex !== null &&
    paths[pathIndex].path_elements.map((d, i) => {
      const { xpoint, ypoint } = updatedData.find(
        (item) => item.business_title === d.business_title
      );
      return (
        <g key={i}>
          <circle
            key={i}
            r={7} // radius
            // cx={xScale(d.career_band) + 100} // position on the X axis
            cx={xpoint}
            cy={yScale(d.department)} // on the Y axis
            opacity={1}
            stroke="rgba(192, 192, 192, 1)"
            fill="rgba(192, 192, 192, 1)"
            // fillOpacity={0.2}
            strokeWidth={1}
          />
          <text
            x={xScale(d.career_band) + 90}
            // y={
            //   i % 2 === 0
            //     ? yScale(d.department) + 20
            //     : yScale(d.department) - 20
            // }
            y={ypoint}
            fontSize={11}
            color="red"
            fontWeight={600}
            className={s.selectRole}
            dominantBaseline={"middle"} // vertical alignment
            onClick={(e) => {
              e.preventDefault();
              handleRolePage(true);
              dispatch(updateRoleSelected(d.business_title));
            }}
          >
            {d.business_title}
          </text>
        </g>
      );
    });

  // Build the linepaths
  const allPaths = paths.map((p, index) => {
    const pathColor =
      selectPathColor !== null && selectPathColor === p.path_sequence
        ? "rgba(192, 192, 192, 1)"
        : p.path_sequence === 1
        ? "rgba(225, 36, 42, 1)"
        : "rgba(192, 192, 192, 1)";
    const currPath = selectPathColor === 1 ? "rgba(225, 36, 42, 1)" : pathColor;
    return (
      <path
        d={linePath[index]}
        opacity={1}
        // stroke="#9a6fb0"
        stroke={currPath}
        fill="none"
        strokeWidth={
          selectPathColor !== null && selectPathColor === p.path_sequence
            ? 6
            : 3
        }
        // dx={ele.career_band}
        // dy={ele.department}
        style={
          selectPathColor !== null && pathIndex !== index
            ? { display: "none" }
            : null
        }
        onMouseEnter={() => {
          setSelectPathColor(p.path_sequence);
          setPathIndex(index);
        }}
        onMouseLeave={() => {
          setSelectPathColor(null);
          setPathIndex(null);
        }}
      />
    );
  });

  // eslint-disable-next-line
  const xLabels = allXGroups.map((name, i) => {
    const xPos = xScale(name) ?? 0;
    return (
      <text
        key={i}
        // x={xPos + xScale.bandwidth()}
        x={xPos + 150}
        y={boundsHeight - 20}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={11}
      >
        {name}
      </text>
    );
  });

  const yLabels = allYGroups.map((name, i) => {
    const funcName = data.find((item) => item.department === name);
    const yPos = yScale(name) ?? 0;
    const fillColor = colorArray.find((arr) => arr.name === funcName.function);
    return (
      <g>
        <rect
          // className={s.departmentBox}
          width={150}
          height={40}
          x={-60}
          y={yPos - 20}
          fill={fillColor && fillColor.color ? fillColor.color : "#DDDDDD"}
          opacity={0.2}
        ></rect>
        {/* <text
          key={i}
          x={90}
          // y={yPos + yScale.bandwidth()}
          y={yPos}
          textAnchor="end"
          dominantBaseline="middle"
          style={{
            width: "150px",
            whiteSpace: "pre-wrap",
            fontSize: "11px",
          }}
        >
          
        </text> */}

        <foreignObject
          width="150"
          height="50"
          x={-50}
          // y={yPos + yScale.bandwidth()}
          y={yPos - 15}
          style={{
            // width: "150px",
            whiteSpace: "pre-wrap",
            fontSize: "11px",
          }}
          requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        >
          <p xmlns="http://www.w3.org/1999/xhtml" className={s.departmentText}>
            {name === "Global Quality"
              ? "GQ OPU"
              : name === "Global Manufacturing & Supply"
              ? "GMS OPU"
              : name}
          </p>
        </foreignObject>
      </g>
    );
  });
  return (
    <div className={s.chartContainer}>
      <svg width={width} height={height} className={s.chart}>
        <g
          width={boundsWidth - 100}
          height={boundsHeight}
          transform={`translate(42,50)`}
          style={{ zoom: "80%" }}
        >
          {" "}
          {allPaths}
          {selectPathColor !== null && pathIndex !== null
            ? allRects1
            : allRects}
          {/* {xLabels} */}
          {yLabels}
        </g>
      </svg>
    </div>
  );
};
