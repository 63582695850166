import {
  OPERATING_UNIT_UPDATED,
  SITE_DATA_UPDATED,
  ACTIVE_SITE_SELECTED,
  CURRENT_ROLE_LIST,
  CURRENT_ROLE_SELECTED,
  ASPIRED_ROLE_SELECTED,
  CURRENT_SELECTED_FUNCTION,
  FULL_VIEW_FLAG,
  ROLE_SELECTED,
  ORG_SELECTED_FUNCTION,
  ORG_SUB_SELECTED_FUNCTION,
  ORG_SELECTED_HEAD_FUNCTION,
  ORG_SIDE_EXPAND_MENU,
} from "../actions";

//initial state
const initialState = {
  operatingUnitDataList: [],
  siteDataList: [],
  userActiveSiteSelected: {
    label: "Select Site",
    value: "*",
    status: "Active",
  },
  currentRoleList: [],
  currentRoleSelected: null,
  aspiredRoleSelected: null,
  currentSelectedFunction: null,
  fullViewflag: false,
  roleSelected: null,
  orgSelectedFunction: null,
  orgSubSelectedFunction: null,
  orgSelectedHeadFunction: null,
  orgSideExpandMenu: null,
};

//reducers
// Store the list of Operating Units and gets updated based on user selection
// the type is Array of Objects.
export function storeOperatingUnitData(
  state = initialState.operatingUnitDataList,
  action
) {
  switch (action.type) {
    case OPERATING_UNIT_UPDATED:
      return action.payload;
    default:
      return state;
  }
}
// Store the list of Sites available based on the Operating Unit selected and gets updated based on user selection
// the type is Array of Objects.
export function storeSiteData(state = initialState.siteDataList, action) {
  switch (action.type) {
    case SITE_DATA_UPDATED:
      return action.payload;
    default:
      return state;
  }
}
// Store the Active Site selected and gets updated based on user selection
// the type is Object.
export function storeUserActiveSiteSelected(
  state = initialState.userActiveSiteSelected,
  action
) {
  switch (action.type) {
    case ACTIVE_SITE_SELECTED:
      return action.payload;
    default:
      return state;
  }
}
export function storeCurrentRoleList(
  state = initialState.currentRoleSelected,
  action
) {
  switch (action.type) {
    case CURRENT_ROLE_LIST:
      return action.payload;
    default:
      return state;
  }
}

export function storeUserCurrentRoleSelected(
  state = initialState.currentRoleSelected,
  action
) {
  switch (action.type) {
    case CURRENT_ROLE_SELECTED:
      return action.payload;
    default:
      return state;
  }
}

export function storeUserAspiredRoleSelected(
  state = initialState.aspiredRoleSelected,
  action
) {
  switch (action.type) {
    case ASPIRED_ROLE_SELECTED:
      return action.payload;
    default:
      return state;
  }
}

export function storeUserCurrentSelectedFunction(
  state = initialState.currentSelectedFunction,
  action
) {
  switch (action.type) {
    case CURRENT_SELECTED_FUNCTION:
      return action.payload;
    default:
      return state;
  }
}
export function storeFullViewFlagFunction(
  state = initialState.fullViewflag,
  action
) {
  switch (action.type) {
    case FULL_VIEW_FLAG:
      return action.payload;
    default:
      return state;
  }
}
export function storeRoleSelectedFunction(
  state = initialState.roleSelected,
  action
) {
  switch (action.type) {
    case ROLE_SELECTED:
      return action.payload;
    default:
      return state;
  }
}

export function storeOrgSelectedFunction(
  state = initialState.orgSelectedFunction,
  action
) {
  switch (action.type) {
    case ORG_SELECTED_FUNCTION:
      return action.payload;
    default:
      return state;
  }
}
export function storeOrgSubSelectedFunction(
  state = initialState.orgSubSelectedFunction,
  action
) {
  switch (action.type) {
    case ORG_SUB_SELECTED_FUNCTION:
      return action.payload;
    default:
      return state;
  }
}
export function storeOrgSelectedHeadFunction(
  state = initialState.orgSelectedHeadFunction,
  action
) {
  switch (action.type) {
    case ORG_SELECTED_HEAD_FUNCTION:
      return action.payload;
    default:
      return state;
  }
}

export function storeOrgSideMenuFunction(
  state = initialState.orgSideExpandMenu,
  action
) {
  switch (action.type) {
    case ORG_SIDE_EXPAND_MENU:
      return action.payload;
    default:
      return state;
  }
}
