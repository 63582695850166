/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import s from "./style.module.css";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import { updateRoleSelected } from "../../../../store/actions";
import "./chart.css";

const ChartView = ({
  selectedOption,
  selectedHead,
  subOption,
  handleRolePage,
  orgData,
}) => {
  const dispatch = useDispatch();
  const [orgChartData, setOrgChartData] = useState(null);
  const svgRef = useRef();
  const colorArray = [
    { name: "Quality Small Molecules", color: "#9B72B0" },
    { name: "Quality Biologics", color: "#9B72B0" },
    { name: "Quality", color: "#9B72B0" },
    { name: "Environmental, Health & Safety", color: "#ABB436" },
    { name: "Data, Digital & Technology", color: "#EA5532" },
    { name: "Business Excellence", color: "#DA5283" },
    { name: "Engineering", color: "#51B1BF" },
    { name: "Manufacturing Small Molecule", color: "#EBA800" },
    { name: "Manufacturing Biologics", color: "#EBA800" },
    { name: "Manufacturing", color: "#44C28E" },
    { name: "Manufacturing Sciences", color: "#EFB933" },
    { name: "Supply Chain", color: "#4C9BCF" },
    { name: "Global Manufacturing & Supply", color: "#DDDDDD" },
    { name: "Global Quality", color: "#9B72B0" },
  ];
  const handleRoleClick = (role, d) => {
    handleRolePage(true);
    dispatch(updateRoleSelected(d.data.label));
  };

  useEffect(() => {
    if (orgChartData) {
      // const nodeSize = calculateNodeSize(orgChartData);
      const currentColor =
        selectedHead === "Global Quality"
          ? { name: "Global Quality", color: "#9B72B0" }
          : colorArray.find((color) => color.name === selectedOption);
      const root = d3.hierarchy(orgChartData);
      let max_depth = d3.max(root.descendants(), function (d) {
        return d.depth;
      });
      // Define hierarchical layout
      // Define tree layout
      let leaves = root.leaves().length;
      let width =
        max_depth === 1 ? 800 : leaves * 140 <= 860 ? 800 : leaves * 150;
      // const nodeWidth = root.height === 1 ? width / leaves : 110 + 10;
      const nodeWidth =
        leaves <= 4
          ? width / leaves
          : root.height === 1
          ? width / leaves
          : 110 + 10;
      const height =
        root.height === 0 ? 400 : root.height === 1 ? 400 : root.height * 300;
      const svg = d3
        .select(svgRef.current)
        .attr("viewBox", `-${width / 2} -${100} ${width} ${height}`)
        .attr("width", width)
        .attr("height", height)
        .style("overflow", "auto")
        .style("margin", "auto")
        .style("margin-top", orgChartData.label === "" ? "-150px" : "auto")
        .style("display", "block");

      const treeLayout = d3
        .tree()
        .nodeSize([nodeWidth, 85 + 120])
        .separation((a, b) => (a.parent === b.parent ? 1 : 1.25));

      const diagonal = d3
        .linkVertical()
        .x((d) => d.x)
        .y((d) => d.y);

      // Assigns the x and y position for the nodes
      treeLayout(root);

      svg
        .selectAll(".link")
        .data(root.links())
        .enter()
        .append("path")
        .attr("class", function (d) {
          if (d.target.data.connectionType === "secondary") {
            return "dashed";
          } else {
            return "solid";
          }
        })
        .attr("d", (d) => {
          let sourceY = d.source.parent === null ? d.source.y : d.source.y + 15;
          let targetY = d.target.y - 70;
          let sourceX = d.source.x;
          let targetX = d.target.x;
          return diagonal({
            source: { x: sourceX, y: sourceY },
            target: { x: targetX, y: targetY },
          });
        })
        .attr("fill", "none")
        .style("stroke", (d) =>
          d.source.data.label === ""
            ? "white"
            : d.target.data.connectionType === "secondary"
            ? "black"
            : "#DDDDDD"
        )
        .style("stroke-width", (d) =>
          d.source.data.label === ""
            ? "0px"
            : d.target.data.connectionType === "secondary"
            ? "2px"
            : "15px"
        )
        .style("stroke-opacity", "0.4");
      const nodes = svg
        .selectAll(".node")
        .data(root.descendants())
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", (d) => `translate(${d.x},${d.y})`);
      const maxHeight = 85;
      const maxWidth = 110;
      nodes
        .append("rect")
        .attr("class", "node-rect")
        .attr("width", (d) => {
          if (d.parent === null) {
            return 200;
          }
          return maxWidth;
        })
        .attr("height", (d) => {
          if (d.parent === null) {
            return 50;
          }
          // let height =
          //   d.data.label.length * 5 >= 90
          //     ? maxHeight
          //     : d.data.label.length * 5 <= 60
          //     ? minHeight
          //     : d.data.label.length * 5;
          return maxHeight;
        })
        .attr("x", (d) => {
          if (d.parent === null) {
            return -100;
          }
          return -maxWidth / 2;
        }) // Adjust x position for centering
        .attr("y", (d) => {
          if (d.parent === null) {
            return -50;
          }
          return -70;
        }) // Adjust y position for centering
        .style(
          "fill",
          (d) => {
            return d.data.label === ""
              ? "white"
              : currentColor && currentColor.color
              ? `${currentColor.color}CC`
              : "#DDDDDD";
          }
          // currentColor && currentColor.color
          //   ? `${currentColor.color}`
          //   : "#DDDDDD"
        )
        .attr("fill-opacity", "0.4"); // Node color

      const fobj = nodes
        .append("foreignObject")
        .attr("x", (d) => {
          if (d.parent === null) {
            return -100;
          }
          return -maxWidth / 2;
        })
        .attr("y", (d) => {
          if (d.parent === null) {
            return -50;
          }
          return -70;
        })
        .attr("width", (d) => {
          if (d.parent === null) {
            return 200;
          }
          // return d.data.label.length * 5 >= 110
          //   ? maxWidth
          //   : d.data.label.length * 5 <= 65
          //   ? minWidth
          //   : d.data.label.length * 5;
          return maxWidth;
        })
        .attr("height", (d) => {
          if (d.parent === null) {
            return 50;
          }
          // return d.data.label.length * 5 >= 90
          //   ? maxHeight
          //   : d.data.label.length * 5 <= 60
          //   ? minHeight
          //   : d.data.label.length * 5;
          return maxHeight;
        });

      fobj
        .append("xhtml:div")
        .attr("class", "node-text")
        .style("width", "100%")
        .style("height", "100%")
        .text((d) => {
          return d.data.label;
        })
        .attr("title", (d) => d.data.label)
        .on("click", handleRoleClick)
        .style("pointer-events", "all")
        .on("mouseover", function () {
          d3.select(this)
            .style("text-decoration", "underline")
            .style("cursor", "pointer");
        })
        .on("mouseout", function () {
          d3.select(this).style("text-decoration", "none");
        });

      nodes.each(function (d) {
        const secondaryCHeck =
          d.data.children &&
          d.data.children.some((child) => child.connectionType === "secondary");
        if (secondaryCHeck) {
          d3.select(this)
            .append("rect")
            .attr("width", 10)
            .attr("height", 10)
            .attr("x", -5.5)
            .attr("y", 1)
            .style(
              "fill",
              currentColor && currentColor.color
                ? currentColor.color
                : "#DDDDDD"
            )
            .style("stroke", "black")
            .style("stroke-width", "1px");
        }
      });
    }
  }, [selectedOption, orgChartData]);

  useEffect(() => {
    if (orgData) {
      setOrgChartData(orgData);
    } else {
      setOrgChartData(null);
    }
  }, [orgData]);

  return (
    <div className={s.container}>
      <div className={s.header}>
        {selectedOption === "Global Quality"
          ? "GQ Site Leadership Roles"
          : selectedOption === "Global Manufacturing & Supply"
          ? "GMS Site Leadership Roles"
          : selectedOption}
      </div>
      <div className={s.chart} style={{ textAlign: "center" }}>
        <svg ref={svgRef}></svg>
      </div>
      <div className={s.legend}>
        <div className={s.legend1}>
          <div className={s.dotted}></div>
          <div className={s.legendLabel}>Connection but does not report to</div>
        </div>
        <div className={s.legend1}>
          <div className={s.block}></div>
          <div className={s.legendLabel}>Reporting relationship</div>
        </div>
      </div>
    </div>
  );
};

export default ChartView;
