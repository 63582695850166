import React, { useEffect, useRef, useState } from "react";
import s from "./style.module.css";
import { useSelector } from "react-redux";
import { baseURL } from "../getFromApi";
import axios from "axios";
import Loader from "../Loader/Loader";
import ErrorPopup from "../ErrorPopup/ErrorPopup";

const RolePage = ({ handleRolePage, selectedActiveSite }) => {
  const iconRef = useRef();
  const storeData = useSelector((state) => state);
  const [roleInfo, setRoleInfo] = useState({});
  const [error, setError] = useState(null);
  const [modal, isModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  const [leftVal, setLeftVal] = useState(0);
  const [expandJR, setExpandJR] = useState(false);
  const [expandTS, setExpandTS] = useState(false);
  const [expandDS, setExpandDS] = useState(false);
  const [expandPS, setExpandPS] = useState(false);

  const getCleanJRText = (para) => {
    const sentences = !expandJR
      ? para &&
        para.length > 0 &&
        para.split("\n").filter((item) => item !== "")
      : para &&
        para.length > 0 &&
        para
          .split("\n")
          .filter((item) => item !== "")
          .slice(0, 3);
    const handleClick = () => {
      setExpandJR(!expandJR);
    };
    return (
      <div className={s.contentBlock}>
        {sentences &&
          sentences.length > 0 &&
          sentences.map((sentence, index) => {
            return (
              <div key={index} className={s.sentence}>
                {sentence[0] !== "∙" ? (
                  <div style={{ marginTop: "2px" }}>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="white"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="#9B72B0"
                        fill-opacity="0.2"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                      <path
                        d="M7.4654 10.0862L10.0843 12.5862L14.4492 7.58621"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                    </svg>
                  </div>
                ) : (
                  <div style={{ marginLeft: "50px" }}>{`      `}</div>
                )}
                <div>{sentence}</div>
              </div>
            );
          })}
        {sentences && sentences.length > 2 && (
          <div className={s.seeMoreBtn} onClick={() => handleClick()}>
            {expandJR ? `See More` : `See Less`}
          </div>
        )}
      </div>
    );
  };
  const getCleanTSText = (para) => {
    const sentences = !expandTS
      ? para &&
        para.length > 0 &&
        para.split("\n").filter((item) => item !== "")
      : para &&
        para.length > 0 &&
        para
          .split("\n")
          .filter((item) => item !== "")
          .slice(0, 3);
    const handleClick = () => {
      setExpandTS(!expandTS);
    };
    return (
      <div className={s.contentBlock}>
        {sentences &&
          sentences.length > 0 &&
          sentences.map((sentence, index) => {
            return (
              <div key={index} className={s.sentence}>
                {sentence[0] !== "∙" ? (
                  <div style={{ marginTop: "2px" }}>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="white"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="#9B72B0"
                        fill-opacity="0.2"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                      <path
                        d="M7.4654 10.0862L10.0843 12.5862L14.4492 7.58621"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                    </svg>
                  </div>
                ) : (
                  <div style={{ marginLeft: "50px" }}>{`      `}</div>
                )}
                <div>{sentence}</div>
              </div>
            );
          })}
        {sentences && sentences.length > 2 && (
          <div className={s.seeMoreBtn} onClick={() => handleClick()}>
            {expandTS ? `See More` : `See Less`}
          </div>
        )}
      </div>
    );
  };
  const getCleanDSText = (para) => {
    const sentences = !expandDS
      ? para &&
        para.length > 0 &&
        para.split("\n").filter((item) => item !== "")
      : para &&
        para.length > 0 &&
        para
          .split("\n")
          .filter((item) => item !== "")
          .slice(0, 3);
    const handleClick = () => {
      setExpandDS(!expandDS);
    };
    return (
      <div className={s.contentBlock}>
        {sentences &&
          sentences.length > 0 &&
          sentences.map((sentence, index) => {
            return (
              <div key={index} className={s.sentence}>
                {sentence[0] !== "∙" ? (
                  <div style={{ marginTop: "2px" }}>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="white"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="#9B72B0"
                        fill-opacity="0.2"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                      <path
                        d="M7.4654 10.0862L10.0843 12.5862L14.4492 7.58621"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                    </svg>
                  </div>
                ) : (
                  <div style={{ marginLeft: "50px" }}>{`      `}</div>
                )}
                <div>{sentence}</div>
              </div>
            );
          })}
        {sentences && sentences.length > 2 && (
          <div className={s.seeMoreBtn} onClick={() => handleClick()}>
            {expandDS ? `See More` : `See Less`}
          </div>
        )}
      </div>
    );
  };
  const getCleanPSText = (para) => {
    const sentences = !expandPS
      ? para &&
        para.length > 0 &&
        para.split("\n").filter((item) => item !== "")
      : para &&
        para.length > 0 &&
        para
          .split("\n")
          .filter((item) => item !== "")
          .slice(0, 3);
    const handleClick = () => {
      setExpandPS(!expandPS);
    };
    return (
      <div className={s.contentBlock}>
        {sentences &&
          sentences.length > 0 &&
          sentences.map((sentence, index) => {
            return (
              <div key={index} className={s.sentence}>
                {sentence[0] !== "∙" ? (
                  <div style={{ marginTop: "2px" }}>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="white"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        fill="#9B72B0"
                        fill-opacity="0.2"
                      />
                      <ellipse
                        cx="10.8371"
                        cy="10.3448"
                        rx="8.66957"
                        ry="8.27586"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                      <path
                        d="M7.4654 10.0862L10.0843 12.5862L14.4492 7.58621"
                        stroke="black"
                        stroke-width="1.20833"
                      />
                    </svg>
                  </div>
                ) : (
                  <div style={{ marginLeft: "50px" }}>{`      `}</div>
                )}
                <div>{sentence}</div>
              </div>
            );
          })}
        {sentences && sentences.length > 2 && (
          <div className={s.seeMoreBtn} onClick={() => handleClick()}>
            {expandPS ? `See More` : `See Less`}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const currentRole = storeData.storeRoleSelectedFunction;
    const colorArray = [
      { name: "Quality Small Molecules", color: "#9B72B0" },
      { name: "Quality Biologics", color: "#9B72B0" },
      { name: "Quality", color: "#9B72B0" },
      { name: "Environmental, Health & Safety", color: "#ABB436" },
      { name: "Data, Digital & Technology", color: "#EA5532" },
      { name: "Business Excellence", color: "#DA5283" },
      { name: "Engineering", color: "#51B1BF" },
      { name: "Manufacturing Small Molecule", color: "#EBA800" },
      { name: "Manufacturing Biologics", color: "#EBA800" },
      { name: "Manufacturing", color: "#44C28E" },
      { name: "Manufacturing Sciences", color: "#EFB933" },
      { name: "Supply Chain", color: "#4C9BCF" },
    ];

    setLoader(true);
    axios
      .post(
        `${baseURL}api/get_role_info`,
        {
          site_name:
            selectedActiveSite.value === "Grange Castle"
              ? "Grange_Castle"
              : selectedActiveSite.value,
          business_title: currentRole,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        const colorCode = colorArray.find(
          (arr) => arr.name === response.data.function
        );
        setRoleInfo({
          color: colorCode ? colorCode.color : "#BFBFBF",
          ...response.data,
        });
        let jrState = response.data.job_responsibilities
          .split("\n")
          .filter((item) => item !== "");
        setExpandJR(jrState.length > 3);
        let tsState = response.data.technical_skills
          .split("\n")
          .filter((item) => item !== "");
        setExpandTS(tsState.length > 3);
        let dsState = response.data.digital_skills
          .split("\n")
          .filter((item) => item !== "");
        setExpandDS(dsState.length > 3);
        let psState = response.data.professional_skills
          .split("\n")
          .filter((item) => item !== "");
        setExpandPS(psState.length > 3);

        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
  }, [selectedActiveSite.value, storeData]);

  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    const availableSpacee = {
      top: rect.top,
      right: window.innerWidth - rect.right,
      bottom: window.innerHeight - rect.bottom,
      left: rect.left,
    };
    const x =
      availableSpacee.right < availableSpacee.left
        ? rect.left - 328
        : rect.right;
    const y =
      availableSpacee.bottom < availableSpacee.top
        ? rect.top - 158
        : rect.bottom;

    setShowDiv(true);
    // const data = iconRef.current.offsetLeft;
    setLeftVal({ x, y });
  };
  const handleMouseLeave = () => {
    setShowDiv(false);
  };
  return (
    <div className={s.container}>
      <Loader state={loader} />
      {modal && (
        <ErrorPopup error={error} closeBtn={() => isModalOpen(false)} />
      )}

      <div className={s.navSection}>
        <div className={s.backButton} onClick={() => handleRolePage(false)}>
          <div className={s.backIcon}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 12L4.64645 11.6464L4.29289 12L4.64645 12.3536L5 12ZM17 12.5C17.2761 12.5 17.5 12.2761 17.5 12C17.5 11.7239 17.2761 11.5 17 11.5V12.5ZM8.64645 7.64645L4.64645 11.6464L5.35355 12.3536L9.35355 8.35355L8.64645 7.64645ZM4.64645 12.3536L8.64645 16.3536L9.35355 15.6464L5.35355 11.6464L4.64645 12.3536ZM5 12.5H17V11.5H5V12.5Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={s.backLabel}>Back</div>
        </div>
      </div>
      <div className={s.roleHeader}>
        {roleInfo.generic_job !== "EMPTY" ? (
          <>
            {`${roleInfo.business_title} (${roleInfo.generic_job})`}
            <div
              style={{
                display: "inline-block",
                marginLeft: "20px",
                verticalAlign: "middle",
              }}
            >
              <div
                className={s.infoCircle}
                ref={iconRef}
                onMouseEnter={(e) => handleMouseEnter(e)}
                onMouseLeave={() => handleMouseLeave()}
              >
                i
              </div>
            </div>
          </>
        ) : (
          `${roleInfo.business_title}`
        )}

        {showDiv && (
          <div
            className={s.hoverDiv}
            style={{ left: leftVal.x, top: leftVal.y + 20 }}
          >
            <div className={s.hoverDivHeader}>
              Site Role Name (Global Role Name)
            </div>
            <div className={s.splitter}></div>
            <div className={s.hoverDivBody}>
              Role name standardization is underway so both site specific and
              standardized role names may be displayed for a role.
            </div>
          </div>
        )}
      </div>
      <div className={s.roleTags}>
        <div
          className={s.roleTag}
          style={{
            backgroundColor:
              roleInfo && roleInfo.color ? `${roleInfo.color}33` : "",
            border: `1px solid ${roleInfo && roleInfo.color}`,
          }}
        >
          <div className={s.roleTagsIcon}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.16699"
                y="1.16669"
                width="13.6667"
                height="4.33333"
                stroke="#9B72B0"
              />
              <rect
                x="1.16699"
                y="1.16669"
                width="13.6667"
                height="4.33333"
                stroke="black"
                stroke-opacity="0.4"
              />
              <rect
                x="1.16699"
                y="11.167"
                width="3"
                height="3"
                stroke="#9B72B0"
              />
              <rect
                x="1.16699"
                y="11.167"
                width="3"
                height="3"
                stroke="black"
                stroke-opacity="0.4"
              />
              <rect x="6.5" y="11.167" width="3" height="3" stroke="#9B72B0" />
              <rect
                x="6.5"
                y="11.167"
                width="3"
                height="3"
                stroke="black"
                stroke-opacity="0.4"
              />
              <rect
                x="11.833"
                y="11.167"
                width="3"
                height="3"
                stroke="#9B72B0"
              />
              <rect
                x="11.833"
                y="11.167"
                width="3"
                height="3"
                stroke="black"
                stroke-opacity="0.4"
              />
              <path d="M8 6.00018L8 10.0002" stroke="#9B72B0" />
              <path
                d="M8 6.00018L8 10.0002"
                stroke="black"
                stroke-opacity="0.4"
              />
              <path
                d="M2.66634 10.0003V7.66697L13.333 7.66697V10.0003"
                stroke="#9B72B0"
              />
              <path
                d="M2.66634 10.0003V7.66697L13.333 7.66697V10.0003"
                stroke="black"
                stroke-opacity="0.4"
              />
            </svg>
          </div>
          <div className={s.roleTagLabel}>
            {roleInfo.department === "EMPTY" ||
            roleInfo.department === "Function Head"
              ? roleInfo.function !== "EMPTY"
                ? roleInfo.function
                : roleInfo.gmsgq === "Global Manufacturing & Supply"
                ? "GMS OPU"
                : "GQ OPU"
              : roleInfo.department}
          </div>
        </div>
        {roleInfo.report_to !== "EMPTY" && (
          <div
            className={s.roleTag}
            style={{
              backgroundColor:
                roleInfo && roleInfo.color ? `${roleInfo.color}33` : "",
              border: `1px solid ${roleInfo && roleInfo.color}`,
            }}
          >
            <div className={s.roleTagsIcon}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="15"
                  y="7.5"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 7.5)"
                  stroke="white"
                />
                <rect
                  x="15"
                  y="7.5"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 7.5)"
                  stroke="#9B72B0"
                />
                <rect
                  x="15"
                  y="7.5"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 7.5)"
                  stroke="black"
                  stroke-opacity="0.4"
                />
                <rect
                  x="15"
                  y="14.1667"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 14.1667)"
                  stroke="white"
                />
                <rect
                  x="15"
                  y="14.1667"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 14.1667)"
                  stroke="#9B72B0"
                />
                <rect
                  x="15"
                  y="14.1667"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(90 15 14.1667)"
                  stroke="black"
                  stroke-opacity="0.4"
                />
                <rect
                  x="2.5"
                  y="5.83334"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(-90 2.5 5.83334)"
                  stroke="white"
                />
                <rect
                  x="2.5"
                  y="5.83334"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(-90 2.5 5.83334)"
                  stroke="#9B72B0"
                />
                <rect
                  x="2.5"
                  y="5.83334"
                  width="3.33333"
                  height="3.33333"
                  rx="1.66667"
                  transform="rotate(-90 2.5 5.83334)"
                  stroke="black"
                  stroke-opacity="0.4"
                />
                <path
                  d="M4.16699 6.66666V12.5C4.16699 14.0713 4.16699 14.857 4.65515 15.3452C5.1433 15.8333 5.92898 15.8333 7.50033 15.8333H11.667"
                  stroke="white"
                />
                <path
                  d="M4.16699 6.66666V12.5C4.16699 14.0713 4.16699 14.857 4.65515 15.3452C5.1433 15.8333 5.92898 15.8333 7.50033 15.8333H11.667"
                  stroke="#9B72B0"
                />
                <path
                  d="M4.16699 6.66666V12.5C4.16699 14.0713 4.16699 14.857 4.65515 15.3452C5.1433 15.8333 5.92898 15.8333 7.50033 15.8333H11.667"
                  stroke="black"
                  stroke-opacity="0.4"
                />
                <path
                  d="M4.16699 5.83334V5.83334C4.16699 7.40469 4.16699 8.19037 4.65515 8.67852C5.1433 9.16668 5.92898 9.16668 7.50033 9.16668H11.667"
                  stroke="white"
                />
                <path
                  d="M4.16699 5.83334V5.83334C4.16699 7.40469 4.16699 8.19037 4.65515 8.67852C5.1433 9.16668 5.92898 9.16668 7.50033 9.16668H11.667"
                  stroke="#9B72B0"
                />
                <path
                  d="M4.16699 5.83334V5.83334C4.16699 7.40469 4.16699 8.19037 4.65515 8.67852C5.1433 9.16668 5.92898 9.16668 7.50033 9.16668H11.667"
                  stroke="black"
                  stroke-opacity="0.4"
                />
              </svg>
            </div>
            <div className={s.roleTagLabel}>
              {`Reports to ${roleInfo.report_to}`}
            </div>
          </div>
        )}
        <div
          className={s.roleTag}
          style={{
            backgroundColor:
              roleInfo && roleInfo.color ? `${roleInfo.color}33` : "",
            border: `1px solid ${roleInfo && roleInfo.color}`,
          }}
        >
          <div className={s.roleTagsIcon}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.87794 2.38585L5.33202 15.1H2.4502V2.03903L4.87794 2.38585Z"
                fill="white"
              />
              <path
                d="M4.87794 2.38585L5.33202 15.1H2.4502V2.03903L4.87794 2.38585Z"
                fill="#9B72B0"
                fill-opacity="0.2"
              />
              <path
                d="M4.87794 2.38585L5.33202 15.1H2.4502V2.03903L4.87794 2.38585Z"
                stroke="white"
              />
              <path
                d="M4.87794 2.38585L5.33202 15.1H2.4502V2.03903L4.87794 2.38585Z"
                stroke="#9B72B0"
              />
              <path
                d="M4.87794 2.38585L5.33202 15.1H2.4502V2.03903L4.87794 2.38585Z"
                stroke="black"
                stroke-opacity="0.4"
              />
              <path
                d="M10.0349 11.6006L10.2337 11.7621L10.4809 11.6951L17.0502 9.91592V17.05H2.4502V11.076L7.25422 9.34125L10.0349 11.6006Z"
                fill="white"
              />
              <path
                d="M10.0349 11.6006L10.2337 11.7621L10.4809 11.6951L17.0502 9.91592V17.05H2.4502V11.076L7.25422 9.34125L10.0349 11.6006Z"
                fill="#9B72B0"
                fill-opacity="0.2"
              />
              <path
                d="M10.0349 11.6006L10.2337 11.7621L10.4809 11.6951L17.0502 9.91592V17.05H2.4502V11.076L7.25422 9.34125L10.0349 11.6006Z"
                stroke="white"
              />
              <path
                d="M10.0349 11.6006L10.2337 11.7621L10.4809 11.6951L17.0502 9.91592V17.05H2.4502V11.076L7.25422 9.34125L10.0349 11.6006Z"
                stroke="#9B72B0"
              />
              <path
                d="M10.0349 11.6006L10.2337 11.7621L10.4809 11.6951L17.0502 9.91592V17.05H2.4502V11.076L7.25422 9.34125L10.0349 11.6006Z"
                stroke="black"
                stroke-opacity="0.4"
              />
              <line
                x1="1.9502"
                y1="5.35001"
                x2="4.8752"
                y2="5.35001"
                stroke="white"
              />
              <line
                x1="1.9502"
                y1="5.35001"
                x2="4.8752"
                y2="5.35001"
                stroke="#9B72B0"
              />
              <line
                x1="1.9502"
                y1="5.35001"
                x2="4.8752"
                y2="5.35001"
                stroke="black"
                stroke-opacity="0.4"
              />
              <rect
                x="5.3625"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="white"
              />
              <rect
                x="5.3625"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="#9B72B0"
              />
              <rect
                x="5.3625"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="white"
                stroke-width="0.975"
              />
              <rect
                x="5.3625"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="#9B72B0"
                stroke-width="0.975"
              />
              <rect
                x="5.3625"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="0.975"
              />
              <rect
                x="9.26289"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="white"
              />
              <rect
                x="9.26289"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="#9B72B0"
              />
              <rect
                x="9.26289"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="white"
                stroke-width="0.975"
              />
              <rect
                x="9.26289"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="#9B72B0"
                stroke-width="0.975"
              />
              <rect
                x="9.26289"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="0.975"
              />
              <rect
                x="13.1623"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="white"
              />
              <rect
                x="13.1623"
                y="14.1375"
                width="0.975"
                height="0.975"
                fill="#9B72B0"
              />
              <rect
                x="13.1623"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="white"
                stroke-width="0.975"
              />
              <rect
                x="13.1623"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="#9B72B0"
                stroke-width="0.975"
              />
              <rect
                x="13.1623"
                y="14.1375"
                width="0.975"
                height="0.975"
                stroke="black"
                stroke-opacity="0.4"
                stroke-width="0.975"
              />
            </svg>
          </div>
          <div className={s.roleTagLabel}>
            {roleInfo.site_name && roleInfo.site_name.replace(/_/g, " ")}
          </div>
        </div>
      </div>
      <div className={s.roleBody}>
        <div className={s.sideInfo}>
          <div
            className={s.sideInfoCard}
            style={{
              backgroundColor:
                roleInfo && roleInfo.color ? `${roleInfo.color}` : "#9b72b0",
              border: `1px solid ${roleInfo && roleInfo.color}`,
              boxShadow: `0 4px 8px 0 ${
                roleInfo && roleInfo.color
              }, 0 6px 20px 0 ${roleInfo && roleInfo.color}`,
            }}
          >
            <div className={s.sideInfoCardField}>
              <div className={s.sideInfoCardIcon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <circle
                      cx="12"
                      cy="8"
                      r="4"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={s.sideInfoCardLabel}
                style={{ fontWeight: "700" }}
              >
                {roleInfo.people_manager}
              </div>
            </div>
            <div className={s.sideInfoCardLabel} style={{ marginLeft: "37px" }}>
              Role
            </div>
            <br />
            <div className={s.sideInfoCardField}>
              <div className={s.sideInfoCardIcon}>
                <svg
                  width="30"
                  height="29"
                  viewBox="0 0 30 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      d="M22.7274 20.4471C22.2716 19.1713 21.2672 18.0439 19.8701 17.2399C18.4729 16.4358 16.7611 16 15 16C13.2389 16 11.5271 16.4358 10.1299 17.2399C8.73276 18.0439 7.72839 19.1713 7.27259 20.4471"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <circle
                      cx="15"
                      cy="8"
                      r="4"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      opacity="0.8"
                      d="M15 18L15.8981 20.7639H18.8042L16.4531 22.4721L17.3511 25.2361L15 23.5279L12.6489 25.2361L13.5469 22.4721L11.1958 20.7639H14.1019L15 18Z"
                      fill="white"
                    />
                    <path
                      opacity="0.8"
                      d="M25 18L25.8981 20.7639H28.8042L26.4531 22.4721L27.3511 25.2361L25 23.5279L22.6489 25.2361L23.5469 22.4721L21.1958 20.7639H24.1019L25 18Z"
                      fill="white"
                    />
                    <path
                      opacity="0.8"
                      d="M6 18L6.89806 20.7639H9.80423L7.45308 22.4721L8.35114 25.2361L6 23.5279L3.64886 25.2361L4.54692 22.4721L2.19577 20.7639H5.10194L6 18Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={s.sideInfoCardLabel}
                style={{ fontWeight: "700" }}
              >
                {roleInfo.work_experience}
              </div>
            </div>
            <div className={s.sideInfoCardLabel} style={{ marginLeft: "37px" }}>
              Work Experience
            </div>
            <br />

            <div className={s.sideInfoCardField}>
              <div className={s.sideInfoCardIcon}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      d="M17.5 3.5H17.7C19.4913 3.5 20.387 3.5 20.9435 4.0565C21.5 4.61299 21.5 5.50866 21.5 7.3V7.5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      d="M17.5 20.5H17.7C19.4913 20.5 20.387 20.5 20.9435 19.9435C21.5 19.387 21.5 18.4913 21.5 16.7V16.5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      d="M6.5 3.5H6.3C4.50866 3.5 3.61299 3.5 3.0565 4.0565C2.5 4.61299 2.5 5.50866 2.5 7.3V7.5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      d="M6.5 20.5H6.3C4.50866 20.5 3.61299 20.5 3.0565 19.9435C2.5 19.387 2.5 18.4913 2.5 16.7V16.5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      d="M7.21484 15.7847C7.68758 15.1024 8.37508 14.5254 9.21678 14.1204C10.0585 13.7155 11.0187 13.5 12 13.5C12.9813 13.5 13.9415 13.7155 14.7832 14.1204C15.6249 14.5254 16.3124 15.1024 16.7852 15.7847"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <circle
                      cx="12"
                      cy="9"
                      r="2.5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={s.sideInfoCardLabel}
                style={{ fontWeight: "700" }}
              >
                {roleInfo.digital_personas}
              </div>
            </div>
            <div className={s.sideInfoCardLabel} style={{ marginLeft: "37px" }}>
              Digital Persona
            </div>
            <br />

            <div className={s.sideInfoCardField}>
              <div className={s.sideInfoCardIcon}>
                <svg
                  width="27"
                  height="18"
                  viewBox="0 0 27 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      d="M27 5.64229C26.9979 5.51724 26.9599 5.39542 26.8905 5.29136C26.8212 5.1873 26.7233 5.10538 26.6087 5.05537L15.0008 0.294829C14.0508 -0.0982765 12.9836 -0.0982765 12.0336 0.294829L0.393114 5.05537C0.278481 5.10538 0.180647 5.1873 0.111272 5.29136C0.0418975 5.39542 0.00390969 5.51724 0.0018367 5.64229C-0.000612233 5.67485 -0.000612233 5.70755 0.0018367 5.74011V15.8155C0.0018367 15.9885 0.0705428 16.1543 0.192841 16.2766C0.315139 16.3989 0.48101 16.4676 0.653966 16.4676C0.826921 16.4676 0.992793 16.3989 1.11509 16.2766C1.23739 16.1543 1.3061 15.9885 1.3061 15.8155V6.62048L4.40371 7.95735V14.1526C4.40552 14.2653 4.43651 14.3756 4.49366 14.4727C4.55081 14.5699 4.63216 14.6505 4.72977 14.7069L4.92541 14.8047C7.55308 16.2666 10.5103 17.0338 13.5172 17.0338C16.5241 17.0338 19.4814 16.2666 22.109 14.8047L22.3047 14.7069C22.4023 14.6505 22.4836 14.5699 22.5408 14.4727C22.5979 14.3756 22.6289 14.2653 22.6307 14.1526V7.95735L26.6413 6.22921C26.7499 6.17452 26.8409 6.09058 26.9043 5.98688C26.9677 5.88317 27.0008 5.76382 27 5.64229ZM21.3265 13.7939C18.9293 15.0952 16.2449 15.7769 13.5172 15.7769C10.7896 15.7769 8.10517 15.0952 5.70797 13.7939V8.54426L11.9684 11.2506C12.4575 11.4614 12.9846 11.5702 13.5172 11.5702C14.0499 11.5702 14.5769 11.4614 15.066 11.2506L21.3265 8.54426V13.7939ZM14.5443 10.0442C14.2197 10.1832 13.8703 10.2549 13.5172 10.2549C13.1641 10.2549 12.8147 10.1832 12.4901 10.0442L2.3169 5.67489L12.5227 1.50127C13.1597 1.23858 13.8747 1.23858 14.5117 1.50127L24.6849 5.67489L14.5443 10.0442Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={s.sideInfoCardLabel}
                style={{ fontWeight: "700" }}
              >
                <div> {roleInfo.minimum_education_level}</div>
                <div> {roleInfo.education_focus}</div>
              </div>
            </div>
            <div className={s.sideInfoCardLabel} style={{ marginLeft: "37px" }}>
              Education
            </div>
            <br />

            <div className={s.sideInfoCardField}>
              <div className={s.sideInfoCardIcon}>
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <rect
                      x="17.25"
                      y="8.625"
                      width="3.83333"
                      height="3.83333"
                      rx="1.91667"
                      transform="rotate(90 17.25 8.625)"
                      stroke="white"
                    />
                    <rect
                      x="17.25"
                      y="16.2917"
                      width="3.83333"
                      height="3.83333"
                      rx="1.91667"
                      transform="rotate(90 17.25 16.2917)"
                      stroke="white"
                    />
                    <rect
                      x="2.875"
                      y="6.70834"
                      width="3.83333"
                      height="3.83333"
                      rx="1.91667"
                      transform="rotate(-90 2.875 6.70834)"
                      stroke="white"
                    />
                    <path
                      d="M4.79199 7.66666V14.875C4.79199 16.4463 4.79199 17.232 5.28015 17.7202C5.7683 18.2083 6.55398 18.2083 8.12533 18.2083H13.417"
                      stroke="white"
                    />
                    <path
                      d="M4.79199 6.70834V7.20834C4.79199 8.77968 4.79199 9.56536 5.28015 10.0535C5.7683 10.5417 6.55398 10.5417 8.12533 10.5417H13.417"
                      stroke="white"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={s.sideInfoCardLabel}
                style={{ fontWeight: "700" }}
              >
                {roleInfo.site_level}
              </div>
            </div>
            <div className={s.sideInfoCardLabel} style={{ marginLeft: "37px" }}>
              Level
            </div>
          </div>
        </div>
        <div className={s.mainInfo}>
          <div className={s.jobRespBlock}>
            <div className={s.titleblock}>Job Responsibilities</div>
            {roleInfo && roleInfo.job_responsibilities !== "EMPTY" ? (
              getCleanJRText(roleInfo.job_responsibilities)
            ) : (
              <div className={s.contentBlock}>
                {roleInfo.job_responsibilities}
              </div>
            )}
          </div>
          <div className={s.techSkillBlock}>
            <div className={s.titleblock}>Technical Skills</div>
            {/* <div className={s.contentBlock}> */}
            {roleInfo && roleInfo.technical_skills !== "EMPTY" ? (
              getCleanTSText(roleInfo.technical_skills)
            ) : (
              <div className={s.contentBlock}>{roleInfo.technical_skills}</div>
            )}
            {/* </div>
            <div>See More</div> */}
          </div>
          <div className={s.digitalSkillBlock}>
            <div className={s.titleblock}>Digital Skills</div>
            {/* <div className={s.contentBlock}> */}
            {roleInfo && roleInfo.digital_skills !== "EMPTY" ? (
              getCleanDSText(roleInfo.digital_skills)
            ) : (
              <div className={s.contentBlock}>{roleInfo.digital_skills}</div>
            )}
            {/* </div>
            <div>See More</div> */}
          </div>
          <div className={s.profSkillBlock}>
            <div className={s.titleblock}>Professional Skills</div>
            {/* <div className={s.contentBlock}> */}
            {roleInfo && roleInfo.professional_skills !== "EMPTY" ? (
              getCleanPSText(roleInfo.professional_skills)
            ) : (
              <div className={s.contentBlock}>
                {roleInfo.professional_skills}
              </div>
            )}
            {/* </div>
             <div>See More</div> */}
          </div>
          <div className={s.disclaimer}>
            <label style={{ color: "red" }}>*</label>Job titles and description
            may vary in Career Pathways. Please refer to your job title in
            Workday for more details and discuss it with your manager or site
            HR.
          </div>
        </div>
      </div>
    </div>
  );
};
export default RolePage;
