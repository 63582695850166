import React from "react";
import s from "./style.module.css";
import icon1 from "../../../assets/home/icon1.svg";
import icon2 from "../../../assets/home/icon2.svg";
import icon3 from "../../../assets/home/icon3.svg";
import icon4 from "../../../assets/home/icon4.svg";

const Introduction = () => {
  return (
    <React.Fragment>
      <div className={s.mainContent}>
        {/* <p className={s.header}>GMS/GQ Site Career Pathways</p> */}
        <div className={s.subHeaderSection}>
          <div className={s.icon}>
            <svg
              width="289"
              height="90"
              viewBox="0 0 289 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_18536_12406)">
                <path
                  d="M133.062 34.1585C131.722 32.5318 130.066 31.716 128.098 31.716C127.232 31.716 126.43 31.8723 125.689 32.1874C124.95 32.5025 124.315 32.9275 123.782 33.4673C123.25 34.0071 122.83 34.6494 122.526 35.3944C122.219 36.1394 122.067 36.943 122.067 37.8076C122.067 38.6723 122.219 39.5052 122.526 40.2502C122.83 40.9951 123.255 41.6424 123.797 42.192C124.339 42.7416 124.975 43.1739 125.703 43.4865C126.432 43.8016 127.22 43.9579 128.066 43.9579C129.919 43.9579 131.582 43.1739 133.059 41.6033V48.4278L132.468 48.633C131.582 48.9456 130.753 49.1776 129.985 49.3242C129.217 49.4707 128.459 49.544 127.71 49.544C126.174 49.544 124.7 49.2534 123.291 48.6769C121.883 48.098 120.642 47.2847 119.567 46.2344C118.492 45.1865 117.631 43.9409 116.981 42.4973C116.331 41.0562 116.007 39.4832 116.007 37.7759C116.007 36.0686 116.326 34.5053 116.966 33.0838C117.607 31.6622 118.463 30.4361 119.538 29.4078C120.612 28.377 121.856 27.5734 123.277 26.9945C124.695 26.4157 126.182 26.1274 127.74 26.1274C128.626 26.1274 129.499 26.2203 130.355 26.4059C131.212 26.5915 132.114 26.8822 133.059 27.273V34.1561L133.062 34.1585Z"
                  fill="#349BA3"
                />
                <path
                  d="M150.911 45.0717H142.638L141.308 48.926H135.132L143.614 26.7454H149.937L158.419 48.926H152.243L150.914 45.0717H150.911ZM149.373 40.6874L146.772 33.3036L144.171 40.6874H149.373Z"
                  fill="#349BA3"
                />
                <path
                  d="M179.485 48.926H172.274L166.749 40.3942V48.926H160.956V26.7454H169.968C171.209 26.7454 172.292 26.9261 173.219 27.29C174.144 27.654 174.907 28.1474 175.508 28.7751C176.109 29.4028 176.561 30.1283 176.867 30.9514C177.172 31.7745 177.326 32.6587 177.326 33.5991C177.326 35.2869 176.916 36.6547 176.099 37.7026C175.282 38.7529 174.075 39.4636 172.48 39.8349L179.483 48.926H179.485ZM166.749 36.6572H167.843C168.987 36.6572 169.862 36.4227 170.473 35.9513C171.084 35.4799 171.389 34.8033 171.389 33.9215C171.389 33.0398 171.082 32.3632 170.473 31.8918C169.862 31.4204 168.987 31.1859 167.843 31.1859H166.749V36.6572Z"
                  fill="#349BA3"
                />
                <path
                  d="M194.587 31.628H187.703V35.3358H194.204V40.2184H187.703V44.0434H194.587V48.926H181.91V26.7454H194.587V31.628Z"
                  fill="#349BA3"
                />
                <path
                  d="M211.43 31.628H204.546V35.3358H211.048V40.2184H204.546V44.0434H211.43V48.926H198.753V26.7454H211.43V31.628Z"
                  fill="#349BA3"
                />
                <path
                  d="M234.124 48.926H226.913L221.387 40.3942V48.926H215.594V26.7454H224.606C225.848 26.7454 226.93 26.9261 227.857 27.29C228.782 27.654 229.545 28.1474 230.146 28.7751C230.747 29.4028 231.199 30.1283 231.506 30.9514C231.81 31.7745 231.964 32.6587 231.964 33.5991C231.964 35.2869 231.555 36.6547 230.738 37.7026C229.921 38.7529 228.713 39.4636 227.119 39.8349L234.121 48.926H234.124ZM221.387 36.6572H222.481C223.625 36.6572 224.501 36.4227 225.112 35.9513C225.723 35.4799 226.027 34.8033 226.027 33.9215C226.027 33.0398 225.72 32.3632 225.112 31.8918C224.501 31.4204 223.625 31.1859 222.481 31.1859H221.387V36.6572Z"
                  fill="#349BA3"
                />
                <path
                  d="M122.808 77.2595H117.015V55.0789H126.236C128.738 55.0789 130.654 55.7261 131.984 57.0207C133.314 58.3152 133.979 60.1398 133.979 62.492C133.979 64.8441 133.314 66.6687 131.984 67.9632C130.654 69.2578 128.738 69.905 126.236 69.905H122.808V77.2595ZM122.808 65.2569H124.729C126.856 65.2569 127.921 64.3361 127.921 62.492C127.921 60.6478 126.856 59.727 124.729 59.727H122.808V65.2569Z"
                  fill="#349BA3"
                />
                <path
                  d="M149.463 73.4052H141.19L139.86 77.2595H133.685L142.167 55.0789H148.489L156.971 77.2595H150.796L149.466 73.4052H149.463ZM147.927 69.0208L145.327 61.6371L142.726 69.0208H147.927Z"
                  fill="#349BA3"
                />
                <path
                  d="M166.987 59.9615V77.2595H161.194V59.9615H156.437V55.0789H171.744V59.9615H166.987Z"
                  fill="#349BA3"
                />
                <path
                  d="M180.106 63.6375H188.468V55.0764H194.261V77.2571H188.468V68.1073H180.106V77.2571H174.313V55.0764H180.106V63.6375Z"
                  fill="#349BA3"
                />
                <path
                  d="M202.655 55.0764L206.497 69.4019L211.225 55.0764H215.835L220.563 69.4019L224.405 55.0764H230.463L223.814 77.2571H217.903L213.529 64.402L209.154 77.2571H203.243L196.594 55.0764H202.652H202.655Z"
                  fill="#349BA3"
                />
                <path
                  d="M245.265 73.4052H236.992L235.662 77.2595H229.486L237.968 55.0789H244.291L252.773 77.2595H246.597L245.268 73.4052H245.265ZM243.729 69.0208L241.128 61.6371L238.528 69.0208H243.729Z"
                  fill="#349BA3"
                />
                <path
                  d="M258.593 66.6101L250.467 55.0789H257.381L261.518 61.1388L265.625 55.0789H272.539L264.383 66.6101V77.2595H258.591V66.6101H258.593Z"
                  fill="#349BA3"
                />
                <path
                  d="M285.781 60.7553C285.15 60.2449 284.52 59.8687 283.889 59.622C283.259 59.3778 282.648 59.2532 282.056 59.2532C281.308 59.2532 280.697 59.4291 280.224 59.7832C279.75 60.1374 279.515 60.5966 279.515 61.1657C279.515 61.5589 279.632 61.8814 279.87 62.1354C280.106 62.3894 280.417 62.6117 280.8 62.7973C281.185 62.9829 281.617 63.1466 282.101 63.2834C282.584 63.4202 283.06 63.5667 283.533 63.7255C285.425 64.3532 286.809 65.191 287.685 66.2413C288.561 67.2916 289 68.6594 289 70.3447C289 71.483 288.809 72.5113 288.423 73.4345C288.038 74.3578 287.479 75.1468 286.738 75.8038C285.999 76.4608 285.091 76.9713 284.019 77.3328C282.945 77.6943 281.73 77.8775 280.371 77.8775C277.554 77.8775 274.944 77.0446 272.539 75.3764L275.022 70.7282C275.888 71.4927 276.747 72.0618 277.593 72.4355C278.44 72.8093 279.277 72.9949 280.106 72.9949C281.05 72.9949 281.755 72.78 282.218 72.3476C282.682 71.9153 282.913 71.4268 282.913 70.8772C282.913 70.545 282.854 70.2544 282.736 70.0101C282.618 69.7659 282.42 69.5387 282.145 69.3335C281.867 69.1284 281.509 68.9354 281.065 68.7595C280.621 68.5837 280.084 68.3883 279.456 68.1709C278.707 67.9364 277.974 67.675 277.255 67.3917C276.536 67.1084 275.896 66.7298 275.334 66.2584C274.772 65.787 274.318 65.1959 273.974 64.4778C273.628 63.7621 273.457 62.8559 273.457 61.7568C273.457 60.6576 273.638 59.6635 274.004 58.772C274.367 57.8805 274.88 57.116 275.54 56.4785C276.2 55.841 277.007 55.3451 277.964 54.9934C278.918 54.6392 279.988 54.4634 281.171 54.4634C282.275 54.4634 283.426 54.6148 284.628 54.9201C285.83 55.2254 286.981 55.67 288.085 56.2586L285.781 60.7602V60.7553Z"
                  fill="#349BA3"
                />
                <path
                  d="M124.66 20.1973C124.086 20.4025 122.953 20.7395 121.613 20.7395C120.112 20.7395 118.875 20.3585 117.903 19.4352C117.05 18.6145 116.52 17.2955 116.52 15.7568C116.534 12.8111 118.566 10.6567 121.893 10.6567C123.041 10.6567 123.939 10.9059 124.366 11.111L124.057 12.1516C123.527 11.9171 122.864 11.7266 121.863 11.7266C119.449 11.7266 117.874 13.2214 117.874 15.6981C117.874 18.1749 119.39 19.6843 121.701 19.6843C122.541 19.6843 123.115 19.5671 123.409 19.4206V16.4749H121.392V15.449H124.66V20.1973Z"
                  fill="#52B1BF"
                />
                <path
                  d="M134.98 16.2989C134.907 14.9214 134.818 13.2653 134.833 12.0343H134.789C134.45 13.192 134.038 14.4231 133.538 15.786L131.786 20.5783H130.814L129.209 15.8739C128.738 14.4817 128.341 13.2067 128.061 12.0343H128.032C128.002 13.2653 127.929 14.9214 127.84 16.4015L127.575 20.6369H126.354L127.045 10.7593H128.68L130.372 15.5369C130.785 16.7533 131.123 17.8377 131.374 18.8636H131.418C131.668 17.8671 132.021 16.7826 132.463 15.5369L134.229 10.7593H135.864L136.482 20.6369H135.23L134.98 16.2989Z"
                  fill="#52B1BF"
                />
                <path
                  d="M138.277 19.0835C138.852 19.4352 139.691 19.7283 140.574 19.7283C141.884 19.7283 142.65 19.0395 142.65 18.043C142.65 17.1197 142.12 16.5921 140.78 16.0792C139.161 15.5076 138.16 14.6723 138.16 13.28C138.16 11.7412 139.44 10.5981 141.369 10.5981C142.385 10.5981 143.121 10.8326 143.562 11.0818L143.209 12.1223C142.885 11.9464 142.223 11.6533 141.325 11.6533C139.97 11.6533 139.455 12.4594 139.455 13.1335C139.455 14.0568 140.059 14.5111 141.428 15.0387C143.106 15.6835 143.96 16.4895 143.96 17.9404C143.96 19.4645 142.826 20.7835 140.486 20.7835C139.529 20.7835 138.484 20.5051 137.954 20.1533L138.277 19.0835Z"
                  fill="#52B1BF"
                />
                <path
                  d="M144.579 21.2232L148.686 10.5981H149.687L145.565 21.2232H144.579Z"
                  fill="#52B1BF"
                />
                <path
                  d="M158.313 20.1973C157.739 20.4025 156.606 20.7395 155.266 20.7395C153.764 20.7395 152.528 20.3585 151.556 19.4352C150.702 18.6145 150.172 17.2955 150.172 15.7568C150.187 12.8111 152.219 10.6567 155.546 10.6567C156.694 10.6567 157.592 10.9059 158.019 11.111L157.71 12.1516C157.18 11.9171 156.517 11.7266 155.516 11.7266C153.102 11.7266 151.527 13.2214 151.527 15.6981C151.527 18.1749 153.043 19.6843 155.354 19.6843C156.193 19.6843 156.768 19.5671 157.062 19.4206V16.4749H155.045V15.449H158.313V20.1973Z"
                  fill="#52B1BF"
                />
                <path
                  d="M168.457 22.0878C167.117 21.7361 165.807 21.3404 164.658 20.9447C164.452 20.8714 164.246 20.7982 164.055 20.7982C161.699 20.7102 159.683 18.9809 159.683 15.8007C159.683 12.6206 161.626 10.5981 164.305 10.5981C166.984 10.5981 168.751 12.6792 168.751 15.5956C168.751 18.1309 167.573 19.7577 165.924 20.3292V20.3878C166.911 20.637 167.985 20.8714 168.825 21.018L168.457 22.0878ZM167.397 15.6542C167.397 13.6757 166.366 11.6387 164.261 11.6387C162.156 11.6387 161.037 13.6318 161.052 15.7714C161.037 17.8671 162.2 19.7577 164.202 19.7577C166.204 19.7577 167.397 17.9111 167.397 15.6542Z"
                  fill="#52B1BF"
                />
                <path
                  d="M173.359 19.0835C173.933 19.4352 174.772 19.7283 175.655 19.7283C176.965 19.7283 177.731 19.0395 177.731 18.043C177.731 17.1197 177.201 16.5921 175.861 16.0792C174.242 15.5076 173.241 14.6723 173.241 13.28C173.241 11.7412 174.522 10.5981 176.45 10.5981C177.466 10.5981 178.202 10.8326 178.644 11.0818L178.29 12.1223C177.966 11.9464 177.304 11.6533 176.406 11.6533C175.051 11.6533 174.536 12.4594 174.536 13.1335C174.536 14.0568 175.14 14.5111 176.509 15.0387C178.187 15.6835 179.041 16.4895 179.041 17.9404C179.041 19.4645 177.907 20.7835 175.567 20.7835C174.61 20.7835 173.565 20.5051 173.035 20.1533L173.359 19.0835Z"
                  fill="#52B1BF"
                />
                <path
                  d="M182.074 10.7593V20.6369H180.793V10.7593H182.074Z"
                  fill="#52B1BF"
                />
                <path
                  d="M186.196 11.8438H183.178V10.7593H190.524V11.8438H187.491V20.6369H186.196V11.8438Z"
                  fill="#52B1BF"
                />
                <path
                  d="M196.766 16.0058H192.909V19.5671H197.207V20.6369H191.628V10.7593H196.986V11.8291H192.909V14.9507H196.766V16.0058Z"
                  fill="#52B1BF"
                />
                <path
                  d="M55.4257 26.5648L50.1726 33.2501C50.1726 33.2501 50.6118 28.4969 49.2206 24.965C47.8295 21.4331 49.2255 15.1729 49.2255 15.1729L54.3437 16.6115L46.9241 5.64209L36.9872 15.0947L41.8698 15.2632C41.0797 18.0917 41.276 26.7993 41.276 26.7993C41.276 26.7993 42.1102 31.9628 38.0766 38.9192C34.0429 45.8755 22.9185 50.1597 22.9185 50.1597C46.1537 54.9129 53.9266 40.8683 53.9266 40.8683L60.4383 30.9541L64.0794 35.2114L65.419 20.4829L51.4141 23.7804L55.4281 26.5624L55.4257 26.5648Z"
                  fill="#52B1BF"
                />
                <path
                  d="M14.5372 49.1486C17.1871 48.6039 21.5495 47.6196 25.2691 45.2576C28.9887 42.8957 37.7577 35.5339 33.6406 24.3569C29.5236 13.1798 4.70826 12.5643 7.88071 35.0942C8.00585 35.0845 19.4174 37.9007 19.4174 37.9007C19.4174 37.9007 11.4261 31.2008 18.8334 24.1883C27.3939 18.5216 35.2453 31.2375 29.1433 37.0898C23.0413 42.9421 15.3862 40.3408 15.3862 40.3408L0.0244141 37.0068C0.132371 40.7438 0.615722 44.3857 1.44257 47.898L9.04861 49.2512C9.04861 49.2512 11.8898 49.6908 14.5372 49.1486Z"
                  fill="#9B72B0"
                />
                <path
                  d="M76.3324 53.9431C80.5255 51.0194 85.3762 44.935 87.2654 40.5165C87.2237 40.4823 93.4263 44.4929 93.4263 44.4929L87.366 21.2131L70.0832 37.3388L77.0047 38.0715C77.0047 38.0715 72.9931 50.4942 53.2665 50.548C53.2223 50.6042 47.8367 55.7213 41.1655 56.1512C35.6695 56.5053 12.6159 54.0994 2.82861 52.7707C3.75115 55.5063 4.8847 58.1443 6.20962 60.6698C13.8917 61.4221 27.8108 62.7655 27.8108 62.7655C27.8108 62.7655 62.9163 65.6331 76.3275 53.9382L76.3324 53.9431Z"
                  fill="#DA5282"
                />
                <path
                  d="M58.5959 78.8792C69.0701 79.2407 76.551 77.6457 76.551 77.6457L76.3548 84.8487L91.1792 70.0421L71.4427 61.9255L74.441 69.2214C70.9888 71.3952 55.2124 70.3914 45.0498 68.899C37.4732 67.7876 18.068 65.8311 8.68555 64.9079C12.7315 71.1681 18.0287 76.5539 24.2264 80.7111C33.6137 80.2812 52.2829 78.6618 58.5959 78.8767V78.8792Z"
                  fill="#ABB436"
                />
              </g>
              <defs>
                <clipPath id="clip0_18536_12406">
                  <rect width="289" height="90" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.subHeader}>
            Welcome to Career Pathways! This platform will help you better
            understand the organization of a site and will open your eyes on
            career growth pathways across different functions. Here, you will
            find all what you need to plan your individual development and grow
            your career within Takeda.
          </div>
        </div>
        <div className={s.subContent}>
          <div className={s.subParts}>
            <div className={s.imageBlock}>
              <div className={`${s.imageContainer} ${s.color1}`}>
                <img src={icon1} alt="Career Pathways" />
              </div>
            </div>
            <p className={s.heading}>Career Pathways</p>
            <p className={s.subHeading}>
              Discover exciting career pathways within and across sites
            </p>
          </div>
          <div className={s.subParts}>
            <div className={s.imageBlock}>
              <div className={`${s.imageContainer} ${s.color2}`}>
                <img src={icon2} className={s.image2} alt="Success Stories" />
              </div>
            </div>
            <p className={s.heading}>Success Stories</p>
            <p className={s.subHeading}>
              Get inspired by others who have successfully progressed their
              careers
            </p>
          </div>
          <div className={s.subParts}>
            <div className={s.imageBlock}>
              <div className={`${s.imageContainer} ${s.color3}`}>
                <img src={icon3} alt="Organization" />
              </div>
            </div>
            <p className={s.heading}>Organization</p>
            <p className={s.subHeading}>
              Learn about site functions, departments and structures
            </p>
          </div>
          <div className={s.subParts}>
            <div className={s.imageBlock}>
              <div className={`${s.imageContainer} ${s.color4}`}>
                <img src={icon4} className={s.image4} alt="Resources" />
              </div>
            </div>
            <p className={s.heading}>Resources & FAQ</p>
            <p className={s.subHeading}>
              Explore career development, lifelong learning and how career
              pathways can support you
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Introduction;
