/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import s from "./style.module.css";
import RolePath from "./RolePath";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

const AspiredRoleChart = ({
  customRole,
  aspiredRole,
  handleRolePage,
  rolePaths,
}) => {
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState([]);

  useEffect(() => {
    setCount(1);
    setTotal([]);
    let newArray = [];
    let array = [...rolePaths.value].reverse();
    for (let i = 0; i < array.length; i += 3) {
      newArray.push(array.slice(i, i + 3).reverse());
    }
    setTotal(newArray);
  }, [rolePaths]);
  return (
    <div>
      <div className={s.siteNote}>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
              fill="#9B72B0"
            />
          </svg>
        </div>
        <div className={s.note}>
          Here are the{" "}
          <span className={s.headerText}>most direct career pathways</span> from
          <span className={s.headerText}> {customRole}</span> to
          <span className={s.headerText}> {aspiredRole}</span> for you to{" "}
          <span className={s.headerText}>explore</span>.
          <span> Hover over a pathway to see roles in it</span>.
        </div>
      </div>

      <div className={s.noteContent}>
        <FaArrowRightLong className={s.rightArrow} />
        <p className={s.note}>Most Direct Path</p>
      </div>

      <div className={s.graphContainer}>
        <div className={`${total.length === 1 && s.spacing} ${s.graphContent}`}>
          {total.length > 1 && (
            <FaArrowLeft
              className={
                count > 1
                  ? `${s.rightActive} ${s.leftBtn}`
                  : `${s.arrowBtn} ${s.leftBtn}`
              }
              onClick={() => {
                if (count > 1) {
                  setCount(count - 1);
                }
              }}
            />
          )}
          {total && total.length > 0 && (
            <RolePath
              paths={total[count - 1]}
              handleRolePage={handleRolePage}
            />
          )}
          {total.length > 1 && (
            <FaArrowRight
              className={
                count < total.length
                  ? `${s.rightActive} ${s.rightBtn}`
                  : `${s.arrowBtn} ${s.rightBtn}`
              }
              onClick={() => {
                if (count < total.length) {
                  setCount(count + 1);
                }
              }}
            />
          )}
        </div>
        <div className={s.bottomLines}>
          {total &&
            total.length > 0 &&
            total.map((obj, index) => {
              return (
                <hr
                  className={index + 1 !== count ? s.hrLine : s.hrLineActive}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AspiredRoleChart;
