import s from "./style.module.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import takedalogo from "../../assets/takedalogo.png";
import Resources from "../Resources";
import Organization from "../Organization";
import SuccessStories from "../SuccessStories";
import CareerPathways from "../CareerPathways";
import Home from "../Home";
const LandingPage = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const navigate = useNavigate();
  const handleTabChange = (index) => {
    setSelectedTab(index);
  };
  const navigateHome = () => {
    navigate("/");
    setSelectedTab(1);
  };
  const getlandingPage = (e) => {
    setSelectedTab(e);
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: "en,ja,zh-CN,zh-TW,nl,fr,de,hi,id,ga,it,ms,mr,pt,ru,es,ta"
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <div className={s.header}>
      <div className={s.navigation}>
        <div className={s.logoHolder}>
          <img
            src={takedalogo}
            className={s.logo}
            onClick={navigateHome}
            alt="Takeda Logo"
          />
        </div>
        <div className={s.tabs}>
          <button
            className={selectedTab === 1 ? s.buttonActive : s.button}
            onClick={() => handleTabChange(1)}
          >
            Home
          </button>
          <button
            className={selectedTab === 2 ? s.buttonActive : s.button}
            onClick={() => handleTabChange(2)}
          >
            Career Pathways
          </button>
          <button
            className={selectedTab === 3 ? s.buttonActive : s.button}
            onClick={() => handleTabChange(3)}
          >
            Success Stories
          </button>
          <button
            className={selectedTab === 4 ? s.buttonActive : s.button}
            onClick={() => handleTabChange(4)}
          >
            Organization
          </button>
          <button
            className={selectedTab === 5 ? s.buttonActive : s.button}
            onClick={() => handleTabChange(5)}
          >
            Resources
          </button>
        </div>
        <div
          className={s.languageContainer}
          id="google_translate_element"
        ></div>
      </div>
      {selectedTab === 1 && <Home getlandingPage={getlandingPage} />}
      {selectedTab === 2 && <CareerPathways />}
      {selectedTab === 3 && <SuccessStories />}
      {selectedTab === 4 && <Organization />}
      {selectedTab === 5 && <Resources />}
      <div className={s.footer}>
        <div>
          <svg
            width="36"
            height="30"
            viewBox="0 0 36 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.9353 7.92462L18.9502 10.4567C18.9502 10.4567 19.1162 8.6564 18.5905 7.31867C18.0648 5.98093 18.5924 3.60984 18.5924 3.60984L20.5264 4.15474L17.7227 0L13.9677 3.58024L15.8127 3.64407C15.5142 4.71536 15.5884 8.01344 15.5884 8.01344C15.5884 8.01344 15.9036 9.96915 14.3793 12.6039C12.8551 15.2387 8.65137 16.8613 8.65137 16.8613C17.4316 18.6616 20.3688 13.3422 20.3688 13.3422L22.8295 9.58707L24.2054 11.1996L24.7116 5.62106L19.4194 6.86998L20.9362 7.9237L20.9353 7.92462Z"
              fill="#52B1BF"
            />
            <path
              d="M5.48414 16.4782C6.48547 16.2719 8.13395 15.899 9.53953 15.0044C10.9451 14.1098 14.2588 11.3215 12.703 7.08813C11.1472 2.85476 1.76994 2.62163 2.96876 11.155C3.01604 11.1513 7.32825 12.218 7.32825 12.218C7.32825 12.218 4.3085 9.68033 7.10759 7.0243C10.3424 4.87801 13.3093 9.69421 11.0035 11.9108C8.69767 14.1274 5.80493 13.1422 5.80493 13.1422L0 11.8794C0.0407949 13.2948 0.223445 14.6742 0.535897 16.0045L3.41009 16.517C3.41009 16.517 4.48373 16.6835 5.48414 16.4782Z"
              fill="#9B72B0"
            />
            <path
              d="M28.8354 18.2944C30.4199 17.187 32.2529 14.8825 32.9668 13.209C32.951 13.196 35.2949 14.7151 35.2949 14.7151L33.0048 5.89771L26.4739 12.0054L29.0894 12.2829C29.0894 12.2829 27.5735 16.9881 20.1192 17.0085C20.1025 17.0297 18.0674 18.9679 15.5464 19.1307C13.4696 19.2648 4.758 18.3536 1.05957 17.8503C1.40818 18.8865 1.83653 19.8856 2.33719 20.8422C5.24012 21.1271 10.4999 21.6359 10.4999 21.6359C10.4999 21.6359 23.7657 22.722 28.8335 18.2925L28.8354 18.2944Z"
              fill="#DA5282"
            />
            <path
              d="M22.1332 27.7389C26.0912 27.8758 28.9181 27.2717 28.9181 27.2717L28.844 29.9999L34.4458 24.3918L26.9878 21.3176L28.1208 24.081C26.8163 24.9043 20.8546 24.5241 17.0144 23.9589C14.1513 23.5379 6.8184 22.7969 3.27295 22.4472C4.80183 24.8183 6.80357 26.8582 9.14557 28.4328C12.6929 28.2699 19.7476 27.6566 22.1332 27.738V27.7389Z"
              fill="#ABB436"
            />
          </svg>
        </div>
        <div> Copyright © 2023 Takeda. All Rights Reserved.</div>
      </div>
    </div>
  );
};
export default LandingPage;
