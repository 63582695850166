/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";
import DefaultView from "./DefaultView";
import { IconArray } from "../../../utilities/utils";
import ChartView from "./ChartView";
import Loader from "../../../utilities/Loader/Loader";
import ErrorPopup from "../../../utilities/ErrorPopup/ErrorPopup";
import axios from "axios";
import { baseURL } from "../../../utilities/getFromApi";
import {
  updateOrgSelectedFunction,
  updateOrgSubSelectedFunction,
  updateOrgSelectedHeadFunction,
  udpateOrgSideExpandMenu,
} from "../../../store/actions";

const SiteSelected = ({ handleRolePage, selectedActiveSite }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state);
  const [gmsData, setGmsData] = useState(null);
  const [gqData, setGqData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [subOption, setSubOption] = useState(null);
  const [viewState, setViewState] = useState([]);
  const [error, setError] = useState(null);
  const [modal, isModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedHead, setSelectedHead] = useState(null);

  const handleSelectedOption = (head, option) => {
    setSelectedOption(option === null ? head : option);
    setSelectedHead(head);
    setSubOption(null);
    dispatch(updateOrgSelectedHeadFunction(head));
    dispatch(updateOrgSelectedFunction(option === null ? head : option));
    dispatch(updateOrgSubSelectedFunction(null));
    setLoader(true);
    setOrgData(null);
    const selectedSite =
      selectedActiveSite && selectedActiveSite.value.replace(/\s/g, "_");
    axios
      .post(
        `${baseURL}api/get_organization_chart`,
        {
          selected_site: selectedSite,
          gmsgq: head,
          function: head === "Global Manufacturing & Supply" ? option : null,
          department: head === "Global Manufacturing & Supply" ? null : option,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setOrgData(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
  };
  const handleSelectedSubOption = (option, functionVal) => {
    setSelectedOption(functionVal);
    setSubOption(option);
    setSelectedHead("Global Manufacturing & Supply");
    dispatch(updateOrgSelectedFunction(functionVal));
    dispatch(updateOrgSubSelectedFunction(option));
    setLoader(true);
    setOrgData(null);
    const selectedSite =
      selectedActiveSite && selectedActiveSite.value.replace(/\s/g, "_");
    axios
      .post(
        `${baseURL}api/get_organization_chart`,
        {
          selected_site: selectedSite,
          gmsgq: "Global Manufacturing & Supply",
          function: functionVal,
          department: option,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        setOrgData(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    // if (!storeUserCurrentRoleSelected) {
    setLoader(true);
    // Append "_" for replacing spaces in selectedActiveSite param
    setGqData(null);
    setGmsData(null);
    setOrgData(null);
    setSelectedOption(null);
    setSelectedHead(null);
    const selectedSite =
      selectedActiveSite && selectedActiveSite.value.replace(/\s/g, "_");
    axios
      .post(
        `${baseURL}api/get_organization_menu`,
        {
          selected_site: selectedSite,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (response && response.data.length > 0) {
          setGmsData(response.data[0]);
          if (storeData.storeOrgSideMenuFunction) {
            setViewState(storeData.storeOrgSideMenuFunction);
          } else {
            let openState = response.data[0].value.map((val) => {
              return { value: val.value, state: false };
            });
            setViewState(openState);
            dispatch(udpateOrgSideExpandMenu(openState));
          }
          setGqData(response.data[1]);
        }
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
    // }
  }, [selectedActiveSite]);

  const CollapsibleRoles = ({ val, index, icon }) => {
    const [open, setOpen] = useState(false);
    const currState = viewState.find((item) => item.value === val.value);
    const subList = val.children && val.children.length > 0;

    const handleOpen = (val) => {
      let stateData = viewState.map((item) => {
        if (item.value === val.value) {
          return { value: item.value, state: !item.state };
        } else {
          return { value: item.value, state: false };
        }
      });
      setViewState(stateData);
      dispatch(udpateOrgSideExpandMenu(stateData));
      setOpen(!open);
    };
    return (
      <div className={s.stack} key={`${val.value}${val.label}${val.index}`}>
        <div
          className={s.stackhead}
          key={`${val.value}${val.label}${val.index}`}
        >
          <div
            className={s.functionListVal}
            onClick={() => {
              handleSelectedOption("Global Manufacturing & Supply", val.value);
            }}
            style={{
              color: selectedOption === val.value ? "white" : "black",
              backgroundColor: selectedOption === val.value ? "black" : "white",
            }}
          >
            <div className={s.stackRoleIcon}>
              {selectedOption === val.value
                ? icon && icon.iconDark
                : icon && icon.iconLight}{" "}
              <div>{val.value}</div>
            </div>
            {subList && (
              <button
                style={{
                  width: "36px",
                  height: "36px",
                }}
                onClick={() => handleOpen(val)}
              >
                {currState && currState.state ? (
                  <svg
                    width="37"
                    height="36"
                    viewBox="0 0 37 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="0.1"
                      height="35"
                      stroke="#C0C0C0"
                    />
                    <path
                      d="M24.6621 21L18.4966 15L12.331 21"
                      stroke={selectedOption === val.value ? "white" : "black"}
                    />
                  </svg>
                ) : (
                  <svg
                    width="37"
                    height="36"
                    viewBox="0 0 37 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="0.1"
                      height="35"
                      stroke="#C0C0C0"
                    />
                    <path
                      d="M12.331 15L18.4965 21L24.6621 15"
                      stroke={selectedOption === val.value ? "white" : "black"}
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {currState && currState.state && (
          <div className={s.stackbody} key={`${index}${val.value}${val.label}`}>
            {val.children.map((role, id) => {
              return (
                <div
                  className={s.functionListVal}
                  key={`${val.value}${id}${role.value}`}
                  onClick={() => handleSelectedSubOption(role.value, val.value)}
                  style={{
                    color: subOption === role.value ? "white" : "black",
                    backgroundColor:
                      subOption === role.value ? "#898989" : "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      paddingLeft: "30px",
                    }}
                  >
                    <div className={s.selectRole}>{role.value}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    // setSelectedOption(storeData.storeOrgSelectedFunction);
    // setSubOption(storeData.storeOrgSubSelectedFunction);

    if (storeData.storeOrgSelectedHeadFunction) {
      if (storeData.storeOrgSubSelectedFunction) {
        setViewState(storeData.storeOrgSideMenuFunction);
        handleSelectedSubOption(
          storeData.storeOrgSubSelectedFunction,
          storeData.storeOrgSelectedFunction
        );
      } else {
        handleSelectedOption(
          storeData.storeOrgSelectedHeadFunction,
          storeData.storeOrgSelectedFunction
        );
      }
    }
  }, []);

  return (
    <div className={s.container}>
      <Loader state={loader} />
      {modal && (
        <ErrorPopup error={error} closeBtn={() => isModalOpen(false)} />
      )}
      <div className={s.sideOptions}>
        <div className={s.optionsSection}>
          <div
            className={s.functionHeader}
            onClick={() =>
              handleSelectedOption("Global Manufacturing & Supply", null)
            }
            style={{
              color:
                selectedOption === "Global Manufacturing & Supply"
                  ? "white"
                  : "black",
              backgroundColor:
                selectedOption === "Global Manufacturing & Supply"
                  ? "black"
                  : "white",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {selectedOption === "Global Manufacturing & Supply" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0282 9.34439V8.29683L15.3294 8.03625C15.1891 7.38478 14.9385 6.77501 14.6078 6.22778L15.6301 4.79455L14.2069 3.31442L12.8288 4.37762C12.3026 4.03364 11.7163 3.77827 11.0899 3.62713L10.8393 1.86035H8.82979L8.57923 3.62713C7.95282 3.77305 7.3665 4.03364 6.84032 4.37762L5.46222 3.31442L4.03901 4.79455L5.06132 6.22778C4.73057 6.77501 4.48502 7.38478 4.33969 8.03625L2.64087 8.29683V9.34439"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.71008 9.34456C6.71008 7.55173 8.10823 6.09766 9.83211 6.09766C11.556 6.09766 12.9541 7.55173 12.9541 9.34456"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.41 12.597C13.5403 12.5658 13.6706 12.5449 13.8109 12.5449H16.1362C17.0733 12.5449 17.84 13.3423 17.84 14.3169V17.3189"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.7426 15.2236V17.3396"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.9735 11.8726C15.7069 11.8726 16.3015 11.2542 16.3015 10.4915C16.3015 9.72869 15.7069 9.11035 14.9735 9.11035C14.2401 9.11035 13.6455 9.72869 13.6455 10.4915C13.6455 11.2542 14.2401 11.8726 14.9735 11.8726Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.78894 17.3189V14.3221C1.78894 13.3423 2.55567 12.5449 3.49277 12.5449H5.81801C5.93327 12.5449 6.04852 12.5553 6.15877 12.5814"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.90149 15.2236V17.3396"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.65538 11.8726C5.38881 11.8726 5.98337 11.2542 5.98337 10.4915C5.98337 9.72869 5.38881 9.11035 4.65538 9.11035C3.92195 9.11035 3.32739 9.72869 3.32739 10.4915C3.32739 11.2542 3.92195 11.8726 4.65538 11.8726Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.38428 17.3399V14.8643C6.38428 13.6969 7.30134 12.7432 8.42387 12.7432H11.2051C12.3276 12.7432 13.2447 13.6969 13.2447 14.8643V17.3399"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.71228 15.9429V17.3396"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9318 15.9429V17.3396"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.0645 11.9133C10.9305 11.7671 11.5186 10.9185 11.3781 10.0179C11.2375 9.1172 10.4215 8.5056 9.55548 8.65179C8.68947 8.79799 8.10138 9.64663 8.24196 10.5473C8.38253 11.4479 9.19853 12.0595 10.0645 11.9133Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0282 9.34439V8.29683L15.3294 8.03625C15.1891 7.38478 14.9385 6.77501 14.6078 6.22778L15.6301 4.79455L14.2069 3.31442L12.8288 4.37762C12.3026 4.03364 11.7163 3.77827 11.0899 3.62713L10.8393 1.86035H8.82979L8.57923 3.62713C7.95282 3.77305 7.3665 4.03364 6.84032 4.37762L5.46222 3.31442L4.03901 4.79455L5.06132 6.22778C4.73057 6.77501 4.48502 7.38478 4.33969 8.03625L2.64087 8.29683V9.34439"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.71008 9.34456C6.71008 7.55173 8.10823 6.09766 9.83211 6.09766C11.556 6.09766 12.9541 7.55173 12.9541 9.34456"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.41 12.597C13.5403 12.5658 13.6706 12.5449 13.8109 12.5449H16.1362C17.0733 12.5449 17.84 13.3423 17.84 14.3169V17.3189"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.7426 15.2236V17.3396"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.9735 11.8726C15.7069 11.8726 16.3015 11.2542 16.3015 10.4915C16.3015 9.72869 15.7069 9.11035 14.9735 9.11035C14.2401 9.11035 13.6455 9.72869 13.6455 10.4915C13.6455 11.2542 14.2401 11.8726 14.9735 11.8726Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.78894 17.3189V14.3221C1.78894 13.3423 2.55567 12.5449 3.49277 12.5449H5.81801C5.93327 12.5449 6.04852 12.5553 6.15877 12.5814"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.90149 15.2236V17.3396"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.65538 11.8726C5.38881 11.8726 5.98337 11.2542 5.98337 10.4915C5.98337 9.72869 5.38881 9.11035 4.65538 9.11035C3.92195 9.11035 3.32739 9.72869 3.32739 10.4915C3.32739 11.2542 3.92195 11.8726 4.65538 11.8726Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.38428 17.3399V14.8643C6.38428 13.6969 7.30134 12.7432 8.42387 12.7432H11.2051C12.3276 12.7432 13.2447 13.6969 13.2447 14.8643V17.3399"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.71228 15.9429V17.3396"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9318 15.9429V17.3396"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.0645 11.9133C10.9305 11.7671 11.5186 10.9185 11.3781 10.0179C11.2375 9.1172 10.4215 8.5056 9.55548 8.65179C8.68947 8.79799 8.10138 9.64663 8.24196 10.5473C8.38253 11.4479 9.19853 12.0595 10.0645 11.9133Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {gmsData && gmsData.gmsgqs === "Global Manufacturing & Supply"
              ? "GMS Site Leadership Roles"
              : ""}
          </div>
          <div>
            {gmsData &&
              gmsData.value.map((val, ind) => {
                const findIcon = IconArray.find(
                  (icons) => icons.label === val.value
                );
                return (
                  <CollapsibleRoles val={val} index={ind} icon={findIcon} />
                );
              })}
          </div>
        </div>
        <br />
        <div className={s.optionsSection}>
          <div
            className={s.functionHeader}
            onClick={() => handleSelectedOption("Global Quality", null)}
            style={{
              color: selectedOption === "Global Quality" ? "white" : "black",
              backgroundColor:
                selectedOption === "Global Quality" ? "black" : "white",
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {selectedOption === "Global Quality" ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.3025 13.9702C15.7921 13.9702 15.3779 14.383 15.3779 14.8927C15.3779 15.4024 15.7921 15.8151 16.3025 15.8151C16.8129 15.8151 17.2271 15.4024 17.2271 14.8927C17.2271 14.383 16.8129 13.9702 16.3025 13.9702Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.3024 13.9698V11.8169H14.8092"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.8265 8.22705H16.4884"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.4109 9.15147C16.9012 9.15147 16.4884 8.73726 16.4884 8.22686C16.4884 7.71645 16.9012 7.30225 17.4109 7.30225C17.9205 7.30225 18.3333 7.71645 18.3333 8.22686C18.3333 8.73726 17.9205 9.15147 17.4109 9.15147Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.22717 5.17237V3.51123"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.15183 2.58896C9.15183 3.09864 8.73762 3.51142 8.22722 3.51142C7.71682 3.51142 7.30261 3.09864 7.30261 2.58896C7.30261 2.07928 7.71682 1.6665 8.22722 1.6665C8.73762 1.6665 9.15183 2.07928 9.15183 2.58896Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.69756 6.02948C4.20796 6.02948 4.62217 5.61671 4.62217 5.10703C4.62217 4.59734 4.20796 4.18457 3.69756 4.18457C3.18716 4.18457 2.77295 4.59734 2.77295 5.10703C2.77295 5.61671 3.18716 6.02948 3.69756 6.02948Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.69678 6.02979V8.18266H5.18994"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.17274 11.772H3.5116"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.58908 10.8472C3.09877 10.8472 3.51154 11.2614 3.51154 11.7718C3.51154 12.2822 3.09877 12.6964 2.58908 12.6964C2.0794 12.6964 1.66663 12.2822 1.66663 11.7718C1.66663 11.2614 2.0794 10.8472 2.58908 10.8472Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.02973 16.3025C6.02973 15.7921 5.61695 15.3779 5.10727 15.3779C4.59759 15.3779 4.18481 15.7921 4.18481 16.3025C4.18481 16.8129 4.59759 17.2271 5.10727 17.2271C5.61695 17.2271 6.02973 16.8129 6.02973 16.3025Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.02979 16.3022H8.18266V14.8091"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.7721 14.8262V16.488"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.8474 17.4107C10.8474 16.9011 11.2616 16.4883 11.772 16.4883C12.2824 16.4883 12.6966 16.9011 12.6966 17.4107C12.6966 17.9204 12.2824 18.3332 11.772 18.3332C11.2616 18.3332 10.8474 17.9204 10.8474 17.4107Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9701 3.69756C13.9701 4.20796 14.3829 4.62217 14.8925 4.62217C15.4022 4.62217 15.815 4.20796 15.815 3.69756C15.815 3.18716 15.4022 2.77295 14.8925 2.77295C14.3829 2.77295 13.9701 3.18716 13.9701 3.69756Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9701 3.69678H11.8173V5.18994"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.2773 11.8392V11.0948C12.2737 10.9512 12.1912 10.822 12.0627 10.7574L10.5724 10.0324C10.675 9.91822 10.7813 9.77609 10.8933 9.60523C11.0354 9.38916 11.1101 9.13575 11.1101 8.8766V8.40568C11.1101 7.036 8.87751 7.036 8.87751 8.40568V8.87517C8.87751 9.13503 8.95288 9.38988 9.09574 9.60667C9.20916 9.77896 9.31684 9.9211 9.42021 10.0352L7.93854 10.7517C7.80286 10.8191 7.71887 10.9591 7.72318 11.1106V11.8399"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.0992 10.1379C14.175 7.87413 12.4014 5.97749 10.1377 5.9016C7.87397 5.82571 5.97733 7.59932 5.90144 9.86306C5.82556 12.1268 7.59916 14.0234 9.8629 14.0993C12.1266 14.1752 14.0233 12.4016 14.0992 10.1379Z"
                  stroke="white"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.3025 13.9702C15.7921 13.9702 15.3779 14.383 15.3779 14.8927C15.3779 15.4024 15.7921 15.8151 16.3025 15.8151C16.8129 15.8151 17.2271 15.4024 17.2271 14.8927C17.2271 14.383 16.8129 13.9702 16.3025 13.9702Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.3024 13.9698V11.8169H14.8092"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.8265 8.22705H16.4884"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.4109 9.15147C16.9012 9.15147 16.4884 8.73726 16.4884 8.22686C16.4884 7.71645 16.9012 7.30225 17.4109 7.30225C17.9205 7.30225 18.3333 7.71645 18.3333 8.22686C18.3333 8.73726 17.9205 9.15147 17.4109 9.15147Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.22717 5.17237V3.51123"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.15183 2.58896C9.15183 3.09864 8.73762 3.51142 8.22722 3.51142C7.71682 3.51142 7.30261 3.09864 7.30261 2.58896C7.30261 2.07928 7.71682 1.6665 8.22722 1.6665C8.73762 1.6665 9.15183 2.07928 9.15183 2.58896Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.69756 6.02948C4.20796 6.02948 4.62217 5.61671 4.62217 5.10703C4.62217 4.59734 4.20796 4.18457 3.69756 4.18457C3.18716 4.18457 2.77295 4.59734 2.77295 5.10703C2.77295 5.61671 3.18716 6.02948 3.69756 6.02948Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3.69678 6.02979V8.18266H5.18994"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.17274 11.772H3.5116"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.58908 10.8472C3.09877 10.8472 3.51154 11.2614 3.51154 11.7718C3.51154 12.2822 3.09877 12.6964 2.58908 12.6964C2.0794 12.6964 1.66663 12.2822 1.66663 11.7718C1.66663 11.2614 2.0794 10.8472 2.58908 10.8472Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.02973 16.3025C6.02973 15.7921 5.61695 15.3779 5.10727 15.3779C4.59759 15.3779 4.18481 15.7921 4.18481 16.3025C4.18481 16.8129 4.59759 17.2271 5.10727 17.2271C5.61695 17.2271 6.02973 16.8129 6.02973 16.3025Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.02979 16.3022H8.18266V14.8091"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.7721 14.8262V16.488"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.8474 17.4107C10.8474 16.9011 11.2616 16.4883 11.772 16.4883C12.2824 16.4883 12.6966 16.9011 12.6966 17.4107C12.6966 17.9204 12.2824 18.3332 11.772 18.3332C11.2616 18.3332 10.8474 17.9204 10.8474 17.4107Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9701 3.69756C13.9701 4.20796 14.3829 4.62217 14.8925 4.62217C15.4022 4.62217 15.815 4.20796 15.815 3.69756C15.815 3.18716 15.4022 2.77295 14.8925 2.77295C14.3829 2.77295 13.9701 3.18716 13.9701 3.69756Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.9701 3.69678H11.8173V5.18994"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.2773 11.8392V11.0948C12.2737 10.9512 12.1912 10.822 12.0627 10.7574L10.5724 10.0324C10.675 9.91822 10.7813 9.77609 10.8933 9.60523C11.0354 9.38916 11.1101 9.13575 11.1101 8.8766V8.40568C11.1101 7.036 8.87751 7.036 8.87751 8.40568V8.87517C8.87751 9.13503 8.95288 9.38988 9.09574 9.60667C9.20916 9.77896 9.31684 9.9211 9.42021 10.0352L7.93854 10.7517C7.80286 10.8191 7.71887 10.9591 7.72318 11.1106V11.8399"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.0992 10.1379C14.175 7.87413 12.4014 5.97749 10.1377 5.9016C7.87397 5.82571 5.97733 7.59932 5.90144 9.86306C5.82556 12.1268 7.59916 14.0234 9.8629 14.0993C12.1266 14.1752 14.0233 12.4016 14.0992 10.1379Z"
                  stroke="black"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
            {gqData && gqData.gmsgqs === "Global Quality"
              ? "GQ Site Leadership Roles"
              : ""}
          </div>
          <div>
            {gqData &&
              gqData.value.map((val) => {
                return (
                  <div
                    className={s.functionListVal}
                    onClick={() =>
                      handleSelectedOption("Global Quality", val.value)
                    }
                    style={{
                      color: selectedOption === val.value ? "white" : "black",
                      backgroundColor:
                        selectedOption === val.value ? "black" : "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {selectedOption === val.value ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.06996 3.65603C9.35807 3.45023 9.54734 3.31578 9.70331 3.22911C9.85035 3.1474 9.93232 3.1291 9.99994 3.1291C10.0675 3.1291 10.1495 3.1474 10.2966 3.22911C10.4525 3.31578 10.6418 3.45023 10.9299 3.65603L15.1451 6.66686L9.99994 10.342L4.85479 6.66686L9.06996 3.65603ZM10.3999 11.0394L15.4333 7.44414V10.0002C15.7108 10.0018 15.9792 10.0421 16.2333 10.1161V6.66686C16.2333 6.53768 16.1709 6.41646 16.0658 6.34137L11.3949 3.00504L11.3772 2.99238L11.3772 2.99238C11.1116 2.80269 10.8855 2.64115 10.6851 2.52981C10.4714 2.41104 10.2528 2.3291 9.99994 2.3291C9.74708 2.3291 9.52849 2.41104 9.31474 2.52982C9.11438 2.64115 8.88824 2.80269 8.6227 2.99237L8.6227 2.99238L8.60496 3.00504L3.93411 6.34137C3.82899 6.41646 3.7666 6.53768 3.7666 6.66686V12.3043V12.321V12.321C3.76659 12.559 3.76659 12.7637 3.78209 12.9349C3.79867 13.1181 3.83471 13.2904 3.92177 13.4595C4.00883 13.6287 4.12806 13.7582 4.26749 13.8781C4.39781 13.9903 4.56434 14.1092 4.75807 14.2476L4.77163 14.2573L9.76744 17.8257C9.90652 17.925 10.0934 17.925 10.2324 17.8257L13.7517 15.312C13.5271 15.1555 13.3255 14.9683 13.1531 14.7564L10.3999 16.7229V11.0394ZM9.59994 11.0394V16.7229L5.23662 13.6063C5.02558 13.4555 4.88891 13.3574 4.78927 13.2717C4.69571 13.1912 4.65687 13.1397 4.6331 13.0935C4.60933 13.0473 4.58995 12.9857 4.57883 12.8628C4.56698 12.7319 4.5666 12.5636 4.5666 12.3043V7.44414L9.59994 11.0394Z"
                            fill="white"
                          />
                          <circle
                            cx="15.4168"
                            cy="12.9163"
                            r="2.08333"
                            stroke="white"
                            stroke-width="0.8"
                          />
                          <path
                            d="M17.917 15.416L19.167 16.666"
                            stroke="white"
                            stroke-width="0.8"
                            stroke-linecap="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.06995 3.65603C9.35807 3.45023 9.54734 3.31578 9.70331 3.22911C9.85035 3.1474 9.93232 3.1291 9.99994 3.1291C10.0675 3.1291 10.1495 3.1474 10.2966 3.22911C10.4525 3.31578 10.6418 3.45023 10.9299 3.65603L15.1451 6.66686L9.99994 10.342L4.85479 6.66686L9.06995 3.65603ZM10.3999 11.0394L15.4333 7.44414V10.0002C15.7108 10.0018 15.9792 10.0421 16.2333 10.1161V6.66686C16.2333 6.53768 16.1709 6.41646 16.0658 6.34137L11.3949 3.00504L11.3772 2.99238L11.3772 2.99238C11.1116 2.80269 10.8855 2.64115 10.6851 2.52981C10.4714 2.41104 10.2528 2.3291 9.99994 2.3291C9.74708 2.3291 9.52849 2.41104 9.31474 2.52982C9.11437 2.64115 8.88824 2.80269 8.6227 2.99238L8.62269 2.99238L8.60496 3.00504L3.93411 6.34137C3.82899 6.41646 3.7666 6.53768 3.7666 6.66686V12.3043L3.7666 12.321C3.76659 12.559 3.76659 12.7637 3.78208 12.9349C3.79867 13.1181 3.83471 13.2903 3.92177 13.4595C4.00883 13.6287 4.12806 13.7582 4.26749 13.8781C4.39781 13.9903 4.56434 14.1092 4.75807 14.2476L4.77163 14.2573L9.76744 17.8257C9.90652 17.925 10.0934 17.925 10.2324 17.8257L13.7517 15.312C13.5271 15.1555 13.3255 14.9683 13.1531 14.7564L10.3999 16.7229V11.0394ZM9.59994 11.0394V16.7229L5.23662 13.6063C5.02558 13.4555 4.88891 13.3574 4.78927 13.2717C4.69571 13.1912 4.65687 13.1397 4.6331 13.0935C4.60933 13.0473 4.58995 12.9857 4.57883 12.8628C4.56698 12.7319 4.5666 12.5636 4.5666 12.3043V7.44414L9.59994 11.0394Z"
                            fill="black"
                          />
                          <circle
                            cx="15.4166"
                            cy="12.9163"
                            r="2.08333"
                            stroke="black"
                            stroke-width="0.8"
                          />
                          <path
                            d="M17.9165 15.416L19.1665 16.666"
                            stroke="black"
                            stroke-width="0.8"
                            stroke-linecap="round"
                          />
                        </svg>
                      )}
                      <div>{val.value}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className={s.chartArea}>
        {selectedOption && orgData ? (
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {" "}
            {orgData &&
              orgData.length > 0 &&
              orgData.map((org) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      // width: orgData.length > 1 ? "50%" : "100%",
                    }}
                  >
                    <div className={s.chartView}>
                      <ChartView
                        selectedOption={selectedOption}
                        selectedHead={selectedHead}
                        subOption={subOption}
                        handleRolePage={handleRolePage}
                        orgData={org}
                      />
                    </div>
                    <div className={s.noteLabel}>
                      <label style={{ color: "red" }}>*</label>Illustrative
                      organization chart, excludes support functions
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className={s.defaultView}>
            <DefaultView />
          </div>
        )}
      </div>
    </div>
  );
};
export default SiteSelected;
