/* eslint-disable react-hooks/exhaustive-deps */
import Introduction from "./Introduction";
import SelectSite from "./SelectSite";
import { baseURL } from "../../utilities/getFromApi";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../utilities/Loader/Loader";
import ErrorPopup from "../../utilities/ErrorPopup/ErrorPopup";
import { updateOperatingUnitData, updateSiteData } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
const Home = ({ getlandingPage }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state);

  const [operatingUnitData, setOperatingUnitData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);
  const [modal, isModalOpen] = useState(false);
  useEffect(() => {
    const selectedActiveState = storeData.storeUserActiveSiteSelected;
    if (selectedActiveState && selectedActiveState.show) {
      setOperatingUnitData(storeData.storeOperatingUnitData);
      setSiteData(storeData.storeSiteData);
    } else {
      setLoader(true);
      axios
        .get(
          `${baseURL}api/get_operating_unit`,
          {
            method: "GET",
          },
          {
            headers: { "Content-Type": "*/*" },
          }
        )
        .then((res) => {
          return res;
        })
        .then((data) => {
          const opUnitData = data.data.map((val) => {
            return {
              label: val.operatingUnitName,
              value: val.operatingUnitName,
              ...val,
            };
          });
          setOperatingUnitData(opUnitData);
          dispatch(updateOperatingUnitData(opUnitData));
          setLoader(false);
          const body = opUnitData.map((unit) => unit.value);
          setLoader(true);
          axios
            .post(
              `${baseURL}api/get_site_name`,
              { selected_ou_names: body },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                },
              }
            )
            .then((res) => {
              return res;
            })
            .then((data) => {
              const siteNameData = data.data.map((val) => {
                return {
                  label: val.siteName,
                  value: val.siteName,
                  status: val.show === "Active" ? "Active" : "Comingsoon",
                  stateName: val.state === "EMPTY" ? `, ` : `, ${val.state}, `,
                  ...val,
                };
              });
              setSiteData([
                { label: "Select Site", value: "*", status: "Active" },
                ...siteNameData,
              ]);
              dispatch(
                updateSiteData([
                  { label: "Select Site", value: "*", status: "Active" },
                  ...siteNameData,
                ])
              );

              setLoader(false);
            })
            .catch((error) => {
              setError(error);
              isModalOpen(true);
              setLoader(false);
            });
        })
        .catch((error) => {
          setError(error);
          isModalOpen(true);
          setLoader(false);
        });
    }
  }, []);

  // useEffect(() => {
  //   if (operatingUnitData && operatingUnitData.length > 0) {
  //   }
  // }, [operatingUnitData]);

  return (
    <div>
      <Loader state={loader} />
      {modal && (
        <ErrorPopup error={error} closeBtn={() => isModalOpen(false)} />
      )}
      <Introduction />
      <SelectSite
        operatingUnitData={operatingUnitData}
        siteData={siteData}
        getlandingPage={getlandingPage}
      />
    </div>
  );
};
export default Home;
