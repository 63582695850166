import React, { useEffect, useState } from "react";
import s from "./style.module.css";
import axios from "axios";
import { baseURL } from "../../../utilities/getFromApi";
import Loader from "../../../utilities/Loader/Loader";
import ErrorPopup from "../../../utilities/ErrorPopup/ErrorPopup";

const ResourceLink = () => {
  const [data, setData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [key, setKey] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios
    .get(`${baseURL}api/get_all_resource`, {
      method: "GET",
    })
    .then((response) => {
      setData(response.data);
      setLoader(false);
    })
    .catch((error) => {
      setError(error);
      setLoader(false);
    });
  }, []);
  
  const onMouseOver = (event, index) => {
    event.preventDefault();
    setIsHovered(true);
    setKey(index);
  };
  
  const onMouseOut = (event) => {
    event.preventDefault();
    setIsHovered(false);
    setKey(null);
  };

  const checkHovered = () => {
    if(key !== null && isHovered === true) {
      return true;
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    return window.open(url);
  }

    return (
      <React.Fragment>
        <Loader state={loader} />
        <div className={s.mainContainer}>
          <div className={s.row}>
            {data.length > 0 && data.map((list, index) => {
              return (
                  <div key={index} className={s.col}>
                    <img className={s.image} src={list.imageUrl}
                      alt="Career Navigator" 
                      onMouseOver={(event) => onMouseOver(event, index)}
                      onMouseOut={(event) => onMouseOut(event)}
                      onClick={() => openUrl(list.url)}
                    />
                    <p className={
                      key === index && checkHovered() ? s.hoveredName : s.name}
                      onMouseOver={(event) => onMouseOver(event, index)}
                      onMouseOut={(event) => onMouseOut(event)}
                      onClick={() => openUrl(list.url)}
                    >{list.linkName}</p>
                    <p className={
                      key === index && checkHovered() ? s.hoveredDescription : s.description}
                      onMouseOver={(event) => onMouseOver(event, index)}
                      onMouseOut={(event) => onMouseOut(event)}
                      onClick={() => openUrl(list.url)}
                    >{list.linkDescription}</p>
                  </div>
              )
            })}
          </div>
          {error !== null && 
            <ErrorPopup error={error} closeBtn={() => setError(null)} />
          }
        </div>
      </React.Fragment>
    );
  };
export default ResourceLink;