import React, { useState } from "react";
import ResourceLink from "./ResourceLink";
import FAQ from "./faq";
import s from "./style.module.css";

const Resources = () => {
  const [tab, setTab] = useState(1);

  const handleTabChange = (index) => {
    setTab(index);
  };

  return (
    <React.Fragment>
      <div className={s.mainContainer}>
        <p className={s.header}>Resources</p>
        <div>
          <button
            onClick={() => handleTabChange(1)}
            className={`${tab === 1 ? s.activeButton : s.button}`}
          >
            Resource Links
          </button>
          <button
            onClick={() => handleTabChange(2)}
            className={`${tab === 2 ? s.activeButton : s.button}`}
          >
            FAQ
          </button>
        </div>
        {tab === 1 && <ResourceLink />}
        {tab === 2 && <FAQ />}
      </div>
    </React.Fragment>
  );
};
export default Resources;
