/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";
import RoleFullViewPopup from "./RolesFullViewPopup";
import Lottie from "react-lottie";
import * as animationData1 from "./animation 1.json";
import * as animationData2 from "./animation 2.json";
import {
  updateCurrentSelectedFunction,
  updateFullViewFlag,
  updateRoleSelected,
} from "../../../../store/actions";
const RoleChart = ({
  chartData,
  customRole,
  aspiredRole,
  handleRolePage,
  noProg,
}) => {
  const storeData = useSelector((state) => state);
  const dispatch = useDispatch();
  const [chartDataUI, setChartDataUI] = useState(chartData);
  const [siteNoteMsg, setSiteNoteMsg] = useState("norole");
  const [openFullView, setOpenFullView] = useState(
    storeData.storeFullViewFlagFunction
  );
  const [selectedFunction, setSelectedFunction] = useState(
    storeData.storeUserCurrentSelectedFunction
  );
  const [hasDefaultFunc, sethasDefaultFunc] = useState(null);
  const defaultOptions1 = {
    loop: false,
    autoplay: true,
    animationData: JSON.parse(JSON.stringify(animationData1)),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOptions2 = {
    loop: false,
    autoplay: true,
    animationData: JSON.parse(JSON.stringify(animationData2)),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const circleData =
    chartDataUI &&
    chartDataUI.length > 0 &&
    chartDataUI.filter((head) => head.function_name !== "EMPTY");
  const heads = circleData.length;
  const topBend =
    heads % 2 === 0 ? Math.floor(heads / 2) - 1 : Math.floor(heads / 2);
  const colorArray = [
    { name: "Quality Small Molecules", color: "#9B72B0" },
    { name: "Quality Biologics", color: "#9B72B0" },
    { name: "Quality", color: "#9B72B0" },
    { name: "Environmental, Health & Safety", color: "#ABB436" },
    { name: "Data, Digital & Technology", color: "#EA5532" },
    { name: "Business Excellence", color: "#DA5283" },
    { name: "Engineering", color: "#51B1BF" },
    { name: "Manufacturing Small Molecule", color: "#EBA800" },
    { name: "Manufacturing Biologics", color: "#EBA800" },
    { name: "Manufacturing", color: "#44C28E" },
    { name: "Manufacturing Sciences", color: "#EFB933" },
    { name: "Supply Chain", color: "#4C9BCF" },
  ];
  const leftValues1 = [180, 100, 40, 10, 0, 10, 40, 100, 180];
  const handleFullView = (flag) => {
    dispatch(updateFullViewFlag(flag));
    setOpenFullView(flag);
  };
  const getRolesCount = (func) => {
    let totalRoles = 0;
    func.departments.forEach((dep) => {
      if (dep.roles && Array.isArray(dep.roles)) {
        totalRoles += dep.roles.length;
      }
    });
    return totalRoles;
  };

  const handleBack = () => {
    if (hasDefaultFunc) {
      setSelectedFunction(hasDefaultFunc);
      dispatch(updateCurrentSelectedFunction(hasDefaultFunc));
    } else {
      setSelectedFunction(null);
      dispatch(updateCurrentSelectedFunction(null));
    }
  };
  const CollapsibleRoles = ({ dataList, ind }) => {
    const [open, setOpen] = useState(true);
    return (
      <div className={s.stack}>
        {dataList.departmentName !== "Function Head" && (
          <div className={s.stackhead}>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              {" "}
              <div
                className={s.stackRolecount}
                style={{ backgroundColor: `${selectedFunction.colorCode}4D` }}
              >
                {dataList.roles.length}
              </div>
              <div>{dataList.departmentName}</div>
            </div>
            <div onClick={() => setOpen(!open)}>
              {open ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.11188L12 18.1119"
                    stroke="white"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18 12.1119L6 12.1119"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.22266L12 18.2227"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18 12.2227L6 12.2227"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
        {open && (
          <>
            <div className={s.stackbodyHead}></div>
            <div
              className={s.stackbody}
              key={`${ind}${dataList.departmentName}`}
            >
              {dataList.roles.map((role, id) => {
                return (
                  <div
                    className={s.roletag}
                    key={`${dataList}${id}${role.aspiredRoleName}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {role.upwardLateral === "Upward" ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            fill="white"
                          />
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            fill="#ABB436"
                            fill-opacity="0.6"
                          />
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            stroke="black"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            fill="#E1242A"
                          />
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            fill="white"
                            fill-opacity="0.4"
                          />
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            stroke="black"
                          />
                        </svg>
                      )}
                      <div
                        className={s.selectRole}
                        onClick={() => {
                          handleRolePage(true);
                          dispatch(updateRoleSelected(role.aspiredRoleName));
                        }}
                      >
                        {role.aspiredRoleName}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };
  useEffect(() => {
    const isFunctionSelected = storeData.storeUserCurrentSelectedFunction;
    if (chartData.length > 0) {
      setChartDataUI(chartData);
      const headData = chartData.find((head) => head.function_name === "EMPTY");
      sethasDefaultFunc(headData);
      if (headData && !isFunctionSelected) {
        setSelectedFunction({ colorCode: "#EFEFEF", ...headData });
        dispatch(
          updateCurrentSelectedFunction({ colorCode: "#EFEFEF", ...headData })
        );
      } else {
        // dispatch(updateCurrentSelectedFunction(null));
        setSelectedFunction(storeData.storeUserCurrentSelectedFunction);
      }
      let totalRoleCount = 0;

      chartData.forEach((funct) => {
        let funcCount = getRolesCount(funct);
        totalRoleCount += funcCount;
      });
      let funcLength = chartData.filter(
        (chart) => chart.function_name !== "EMPTY"
      ).length;
      if (chartData[0].gmsgq === null) {
        setSiteNoteMsg("norole");
      } else {
        setSiteNoteMsg({
          totalRoleCount,
          customRole,
          funcLength,
        });
      }
    } else {
      if (!isFunctionSelected) {
        setSelectedFunction(storeData.storeUserCurrentSelectedFunction);
      } else {
        setSelectedFunction(null);
      }
      setSiteNoteMsg("norole");

      // dispatch(updateCurrentSelectedFunction(null));
    }
  }, [chartData, customRole]);

  return (
    <>
      {openFullView && (
        <RoleFullViewPopup
          selectedFunciton={
            selectedFunction
              ? selectedFunction
              : storeData.storeUserCurrentSelectedFunction
          }
          handleFullView={handleFullView}
          handleRolePage={handleRolePage}
        />
      )}
      <div className={s.siteNote}>
        <div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
              fill="#9B72B0"
            />
          </svg>
        </div>
        {noProg && siteNoteMsg !== "norole" ? (
          <div className={s.note}>
            {`There is no direct career pathway from ${customRole} to ${aspiredRole}. Please discuss your aspirations with your manager. You can also consider the below roles for your career development from ${customRole}.`}
          </div>
        ) : (
          <div className={s.note}>
            {siteNoteMsg === "norole"
              ? `Career Pathways have yet to be mapped for this role. Please discuss Career Pathway possibilities with your manager.`
              : ` There are ${siteNoteMsg.totalRoleCount} possible roles to consider for your development from ${siteNoteMsg.customRole} across ${siteNoteMsg.funcLength} functions. Click on a function to view more. `}
          </div>
        )}
      </div>
      {siteNoteMsg !== "norole" && (
        <>
          <div className={s.roleLegends}>
            {
              <div
                className={s.backButton}
                style={{
                  color:
                    selectedFunction &&
                    selectedFunction.function_name !== "EMPTY"
                      ? "black"
                      : "white",
                  pointerEvents:
                    selectedFunction &&
                    selectedFunction.function_name !== "EMPTY"
                      ? ""
                      : "none",
                }}
                onClick={() => {
                  handleBack();
                }}
              >
                <div className={s.backIcon}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12L4.64645 11.6464L4.29289 12L4.64645 12.3536L5 12ZM17 12.5C17.2761 12.5 17.5 12.2761 17.5 12C17.5 11.7239 17.2761 11.5 17 11.5V12.5ZM8.64645 7.64645L4.64645 11.6464L5.35355 12.3536L9.35355 8.35355L8.64645 7.64645ZM4.64645 12.3536L8.64645 16.3536L9.35355 15.6464L5.35355 11.6464L4.64645 12.3536ZM5 12.5H17V11.5H5V12.5Z"
                      fill={
                        selectedFunction &&
                        selectedFunction.function_name !== "EMPTY"
                          ? "black"
                          : "white"
                      }
                    />
                  </svg>
                </div>
                <div className={s.backLabel}>Back</div>
              </div>
            }
            <div className={s.sublegends}>
              <div className={s.sublegend}>
                <div className={s.legendIcon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                      fill="white"
                    />
                    <path
                      d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                      fill="#ABB436"
                      fill-opacity="0.6"
                    />
                    <path
                      d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                      stroke="black"
                    />
                  </svg>
                </div>
                <div className={s.legendLabel}>Upward possible role​</div>
              </div>
              <div className={s.sublegend}>
                <div className={s.legendIcon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                      fill="#E1242A"
                    />
                    <path
                      d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                      fill="white"
                      fill-opacity="0.4"
                    />
                    <path
                      d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                      stroke="black"
                    />
                  </svg>
                </div>
                <div className={s.legendLabel}>Lateral possible role ​</div>
              </div>
            </div>
          </div>
          <div className={s.chartArea}>
            <div className={s.dialView}>
              <div className={s.functionStack}>
                {circleData.map((item, index) => {
                  let left = 0;
                  // let start = index;
                  // if (start < topBend) {
                  //   left = (start - 1) * -60;
                  // } else {
                  //   left = (start - topBend - 1) * 60;
                  // }
                  if (index === topBend) {
                    left = leftValues1[4];
                  } else if (index < topBend) {
                    left = leftValues1[4 - (topBend - index)];
                  } else if (index > topBend) {
                    left = leftValues1[4 + (index - topBend)];
                  }

                  const colorCode = colorArray.find(
                    (arr) => arr.name === item.function_name
                  );
                  let siteName =
                    selectedFunction && selectedFunction.function_name;
                  const opacity = item.function_name === siteName ? "1" : "0.2";
                  return (
                    <div
                      className={s.functionCard}
                      style={{
                        left,
                      }}
                      onClick={() => {
                        dispatch(
                          updateCurrentSelectedFunction({
                            colorCode: colorCode && colorCode.color,
                            ...item,
                          })
                        );

                        setSelectedFunction({
                          colorCode: colorCode && colorCode.color,
                          ...item,
                        });
                      }}
                    >
                      <div className={s.cardLook}>
                        <div
                          className={s.functionCardSVG}
                          style={{ opacity: opacity }}
                        >
                          <svg
                            width="202"
                            height="54"
                            viewBox="0 0 202 54"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M192.498 1.41992H0.420044V42.1399L9.90004 47.8799H178.26H180.8H201.47V38.8799V7L192.498 1.41992Z"
                              fill="white"
                            />
                            <path
                              d="M192.498 1.41992H0.420044V42.1399L9.90004 47.8799H178.26H180.8H201.47V38.8799V7L192.498 1.41992Z"
                              fill={colorCode && colorCode.color}
                              fill-opacity={1}
                            />
                            <path
                              d="M192.498 1.41992H0.420044V42.1399L9.90004 47.8799H178.26H180.8H201.47V38.8799V7L192.498 1.41992Z"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M9.90002 47.8809L16.85 52.5109H56.76V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M198.11 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M194.64 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M191.18 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M187.71 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M184.25 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M180.78 47.8791V39.2891"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M16.8501 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M20.1603 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M23.4801 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M26.79 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M30.11 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M33.42 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M36.7301 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M40.05 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M43.3601 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M46.67 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M49.99 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M53.3 52.5109V47.8809"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M0.420044 6.31992H90.22L95.54 1.41992"
                              stroke="black"
                              stroke-miterlimit="10"
                            />
                          </svg>
                        </div>
                        <div className={s.functionCardLabel}>
                          {item.function_name}
                        </div>
                      </div>
                      <div className={s.cardCount}>
                        <div
                          className={s.cardCircle}
                          style={{
                            backgroundColor:
                              colorCode && colorCode.color
                                ? colorCode.color
                                : "black",
                          }}
                        >
                          {getRolesCount(item)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={s.circle}>
                <Lottie
                  options={selectedFunction ? defaultOptions2 : defaultOptions1}
                  height={450}
                  width={450}
                  isStopped={false}
                  isPaused={false}
                  isClickToPauseDisabled={true}
                />
              </div>
            </div>
            <div className={s.folderView}>
              {selectedFunction && (
                <div className={s.cardView}>
                  <div className={s.folderCardsmallContainer}>
                    <div
                      style={{
                        marginLeft: "2.3rem",
                      }}
                    >
                      <svg
                        width="129"
                        height="20"
                        viewBox="0 0 129 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_13710_35698)">
                          <path
                            d="M43.3831 19.8112L35.3916 19.7686L51.6369 0.00269318H59.6458L43.3831 19.8112Z"
                            fill="white"
                          />
                          <path
                            d="M43.3831 19.8112L35.3916 19.7686L51.6369 0.00269318H59.6458L43.3831 19.8112Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M120.27 1.90735e-06H128.996L112.559 20L103.85 19.9787L120.27 1.90735e-06Z"
                            fill="white"
                          />
                          <path
                            d="M120.27 1.90735e-06H128.996L112.559 20L103.85 19.9787L120.27 1.90735e-06Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M34.4847 0.00141335H42.4936L26.2659 19.7461L18.2744 19.7248L34.4847 0.00141335Z"
                            fill="white"
                          />
                          <path
                            d="M34.4847 0.00141335H42.4936L26.2659 19.7461L18.2744 19.7248L34.4847 0.00141335Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M94.7419 19.9544L86.7329 19.9119L103.118 -0.00302315H111.127L94.7419 19.9544Z"
                            fill="white"
                          />
                          <path
                            d="M94.7419 19.9544L86.7329 19.9119L103.118 -0.00302315H111.127L94.7419 19.9544Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M9.14431 19.7005L-0.00128174 19.6792L16.174 -0.0016098H25.3371L9.14431 19.7005Z"
                            fill="white"
                          />
                          <path
                            d="M9.14431 19.7005L-0.00128174 19.6792L16.174 -0.0016098H25.3371L9.14431 19.7005Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M60.5055 19.8503L52.514 19.829L68.8117 -0.000801086H76.8207L60.5055 19.8503Z"
                            fill="white"
                          />
                          <path
                            d="M60.5055 19.8503L52.514 19.829L68.8117 -0.000801086H76.8207L60.5055 19.8503Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                          <path
                            d="M77.6227 19.8958L69.6313 19.8746L85.9639 0.00221252H93.9729L77.6227 19.8958Z"
                            fill="white"
                          />
                          <path
                            d="M77.6227 19.8958L69.6313 19.8746L85.9639 0.00221252H93.9729L77.6227 19.8958Z"
                            fill={`${selectedFunction.colorCode}`}
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_13710_35698">
                            <rect
                              width="129"
                              height="20"
                              fill="white"
                              transform="matrix(1 0 0 -1 0 20)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={s.folderCard}>
                      <div className={s.folderSVG}>
                        <svg
                          width="394"
                          height="574"
                          viewBox="0 0 394 574"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_dd_14574_5225)">
                            <path
                              d="M94.5294 25L128.266 58.62L353 58.62L353 516.18H63.5815L41 493.67L41 25L94.5294 25Z"
                              fill="white"
                            />
                            <path
                              d="M94.5294 25L128.266 58.62L353 58.62L353 516.18H63.5815L41 493.67L41 25L94.5294 25Z"
                              fill={`${selectedFunction.colorCode}33`}
                              fill-opacity="1"
                            />
                            <path
                              d="M94.5294 25L128.266 58.62L353 58.62L353 516.18H63.5815L41 493.67L41 25L94.5294 25Z"
                              stroke="white"
                              stroke-width="2"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M94.5294 25L128.266 58.62L353 58.62L353 516.18H63.5815L41 493.67L41 25L94.5294 25Z"
                              fill={`${selectedFunction.colorCode}33`}
                              stroke-width="2"
                              stroke-miterlimit="10"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_dd_14574_5225"
                              x="0"
                              y="0"
                              width="394"
                              height="573.18"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="16" />
                              <feGaussianBlur stdDeviation="20" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.364706 0 0 0 0 0.266667 0 0 0 0 0.415686 0 0 0 0.2 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_14574_5225"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="6" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.364706 0 0 0 0 0.266667 0 0 0 0 0.415686 0 0 0 0.2 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_14574_5225"
                                result="effect2_dropShadow_14574_5225"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_14574_5225"
                                result="shape"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                      <div className={s.cardContent}>
                        <div className={s.folderheader}>
                          <div className={s.fhHeader}>Possible Roles</div>
                          <div
                            className={s.fullViewBtn}
                            onClick={() => handleFullView(true)}
                          >
                            Full View{" "}
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20 20V20.5H20.5V20H20ZM15.3536 14.6464C15.1583 14.4512 14.8417 14.4512 14.6464 14.6464C14.4512 14.8417 14.4512 15.1583 14.6464 15.3536L15.3536 14.6464ZM19.5 14V20H20.5V14H19.5ZM20 19.5H14V20.5H20V19.5ZM20.3536 19.6464L15.3536 14.6464L14.6464 15.3536L19.6464 20.3536L20.3536 19.6464Z"
                                fill="#4C4948"
                              />
                              <path
                                d="M4 20H3.5V20.5H4V20ZM9.35355 15.3536C9.54882 15.1583 9.54882 14.8417 9.35355 14.6464C9.15829 14.4512 8.84171 14.4512 8.64645 14.6464L9.35355 15.3536ZM3.5 14V20H4.5V14H3.5ZM4 20.5H10V19.5H4V20.5ZM4.35355 20.3536L9.35355 15.3536L8.64645 14.6464L3.64645 19.6464L4.35355 20.3536Z"
                                fill="#4C4948"
                              />
                              <path
                                d="M20 4H20.5V3.5H20V4ZM14.6464 8.64645C14.4512 8.84171 14.4512 9.15829 14.6464 9.35355C14.8417 9.54882 15.1583 9.54882 15.3536 9.35355L14.6464 8.64645ZM20.5 10V4H19.5V10H20.5ZM20 3.5H14V4.5H20V3.5ZM19.6464 3.64645L14.6464 8.64645L15.3536 9.35355L20.3536 4.35355L19.6464 3.64645Z"
                                fill="#4C4948"
                              />
                              <path
                                d="M4 4V3.5H3.5V4H4ZM8.64645 9.35355C8.84171 9.54882 9.15829 9.54882 9.35355 9.35355C9.54882 9.15829 9.54882 8.84171 9.35355 8.64645L8.64645 9.35355ZM4.5 10V4H3.5V10H4.5ZM4 4.5H10V3.5H4V4.5ZM3.64645 4.35355L8.64645 9.35355L9.35355 8.64645L4.35355 3.64645L3.64645 4.35355Z"
                                fill="#4C4948"
                              />
                            </svg>
                          </div>
                        </div>
                        <div
                          className={s.splitter}
                          style={{
                            border: `1px solid ${selectedFunction.colorCode}`,
                          }}
                        ></div>
                        <div className={s.fcbody}>
                          {selectedFunction &&
                            selectedFunction.departments.map(
                              (sfdata, index) => {
                                return (
                                  <CollapsibleRoles
                                    dataList={sfdata}
                                    ind={index}
                                  />
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RoleChart;
