export const NAME_UPDATED = "profile/updateName";
export const OPERATING_UNIT_UPDATED = "OPERATING_UNIT_DATA_UPDATED";
export const SITE_DATA_UPDATED = "SITE_DATA";
export const ACTIVE_SITE_SELECTED = "ACTIVE_SITE_SELECTED";
export const CP_CHART_DATA = "CP_CHART_DATA";
export const CURRENT_ROLE_LIST = "CURRENT_ROLE_LIST";
export const CURRENT_ROLE_SELECTED = "CURRENT_ROLE_SELECTED";
export const ASPIRED_ROLE_SELECTED = "ASPIRED_ROLE_SELECTED";
export const ROLE_SELECTED = "ROLE_SELECTED";
export const CURRENT_SELECTED_FUNCTION = "CURRENT_SELECTED_FUNCTION";
export const FULL_VIEW_FLAG = "FULL_VIEW_FLAG";
export const ORG_SELECTED_FUNCTION = "ORG_SELECTED_FUNCTION";
export const ORG_SUB_SELECTED_FUNCTION = "ORG_SUB_SELECTED_FUNCTION";
export const ORG_SELECTED_HEAD_FUNCTION = "ORG_SELECTED_HEAD_FUNCTION";
export const ORG_SIDE_EXPAND_MENU = "ORG_SIDE_EXPAND_MENU";

export function updateProfile(text) {
  return { type: NAME_UPDATED, payload: text };
}
export function updateOperatingUnitData(data) {
  return { type: OPERATING_UNIT_UPDATED, payload: data };
}
export function updateSiteData(data) {
  return { type: SITE_DATA_UPDATED, payload: data };
}
export const updateSelectedSite = (data) => {
  return {
    type: ACTIVE_SITE_SELECTED,
    payload: data,
  };
};

export const updateCurrentRoleList = (data) => {
  return {
    type: CURRENT_ROLE_LIST,
    payload: data,
  };
};

export const updateCurrentRoleSelected = (data) => {
  return {
    type: CURRENT_ROLE_SELECTED,
    payload: data,
  };
};
export const updateAspiredRoleSelected = (data) => {
  return {
    type: ASPIRED_ROLE_SELECTED,
    payload: data,
  };
};

export const updateCurrentSelectedFunction = (data) => {
  return {
    type: CURRENT_SELECTED_FUNCTION,
    payload: data,
  };
};
export const updateFullViewFlag = (data) => {
  return {
    type: FULL_VIEW_FLAG,
    payload: data,
  };
};
export const updateCPChartData = (data) => {
  return {
    type: CP_CHART_DATA,
    payload: data,
  };
};

export const updateRoleSelected = (data) => {
  return {
    type: ROLE_SELECTED,
    payload: data,
  };
};

export const updateOrgSelectedFunction = (data) => {
  return {
    type: ORG_SELECTED_FUNCTION,
    payload: data,
  };
};
export const updateOrgSubSelectedFunction = (data) => {
  return {
    type: ORG_SUB_SELECTED_FUNCTION,
    payload: data,
  };
};
export const updateOrgSelectedHeadFunction = (data) => {
  return {
    type: ORG_SELECTED_HEAD_FUNCTION,
    payload: data,
  };
};
export const udpateOrgSideExpandMenu = (data) => {
  return {
    type: ORG_SIDE_EXPAND_MENU,
    payload: data,
  };
};
