import s from "./style1.module.css";
import Location from "../../../assets/successStory/locationLight.svg";
import Flag from "../../../assets/successStory/flagLight.svg";
import { MdOutlineArrowRight } from "react-icons/md";
import * as moment from "moment";

const StoryPath = ({ events, count, total }) => {
  const exactFromDate = (date) => {
    let dateObj = moment(date, "YYYY-MM-DD");
    return (dateObj.format("MMM") + ", " + dateObj.format("YYYY"));
  };

  return (
    <section className={s.timelineWrapper}>
      {events.map((obj, index) => {
        return (
          <div key={index}>
            {index % 2 === 0 ?
              <div className={`${s.box} ${s.boxBottom}`}>
                {obj.additional_details === "EMPTY" ?
                  <div>
                    <div className={s.middleLine}></div>
                    {(index === (events.length - 1) && total === count) &&
                      <MdOutlineArrowRight className={s.arrowButton} />
                    }
                  </div>
                  :
                  <div className={s.dottedMiddleLine}></div>
                }

                {index === 0 && count === 1 ?
                  <div className={s.date}>
                    <img src={Location} alt="Location" className={s.image} />
                  </div>
                  :
                  (index === (events.length - 1) && total === count) ?
                    <div className={s.date}>
                      <img src={Flag} alt="Flag" className={s.flagImage} />
                    </div>
                    :
                    <div className={s.borderDate}></div>
                }

                <div className={s.onBorderText}>
                  <p>{exactFromDate(obj.date)}</p>
                </div>

                <div className={s.boxContent}>
                  {obj.site !== "EMPTY" && <p className={s.site}>{obj.site}</p>}
                  {obj.function !== "EMPTY" && <p className={s.function}>{obj.function}</p>}
                  {obj.additional_details !== "EMPTY" ?
                    <div>
                      {obj.additional_details !== obj.event_name &&
                        <p className={s.eventName}>{obj.event_name}</p>}
                      <p className={s.eventName}>{obj.additional_details}</p>
                    </div>
                    : obj.event_name !== "EMPTY" && <p className={s.eventName}>{obj.event_name}</p>}
                </div>
              </div>
              :
              <div className={`${s.box} ${s.boxTop}`}>
                {obj.additional_details === "EMPTY" ?
                  <div>
                    <div className={s.middleLine}></div>
                    {(index === (events.length - 1) && total === count) &&
                      <MdOutlineArrowRight className={s.arrowButton} />
                    }
                  </div>
                  :
                  <div className={s.dottedMiddleLine}></div>
                }

                {(index === (events.length - 1) && total === count) ?
                  <div className={s.date}>
                    <img src={Flag} alt="Flag" className={s.flagImage} />
                  </div>
                  :
                  <div className={s.borderDate}></div>
                }

                <div className={s.onBorderText}>
                  <p>{exactFromDate(obj.date)}</p>
                </div>

                <div className={s.boxContent}>
                  {obj.additional_details !== "EMPTY" ?
                    <div>
                      <p className={s.eventName}>{obj.additional_details}</p>
                      {obj.additional_details !== obj.event_name &&
                        <p className={s.eventName}>{obj.event_name}</p>}
                    </div>
                    : obj.event_name !== "EMPTY" && <p className={s.eventName}>{obj.event_name}</p>}
                  {obj.function !== "EMPTY" && <p className={s.function}>{obj.function}</p>}
                  {obj.site !== "EMPTY" && <p className={s.site}>{obj.site}</p>}
                </div>
              </div>
            }
          </div>
        )
      })}
    </section >
  )
}

export default StoryPath;