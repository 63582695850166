import s from "./style.module.css";
import defaultView from "../../../../assets/organizations/defaultView.svg";

const DefaultView = () => {
  return (
    <div className={s.mainBlock}>
      <img className={s.image} src={defaultView} alt="Select Site" />
      <div className={s.description}>
        <p className={s.descriptionHeader}>
          In this page, you can view the structure of site.
        </p>
        <div className={s.descriptionText}>
          <div className={s.number}>
            <div className={s.circle}>1</div>
          </div>
          <div>
            <div className={s.subHeader}>Select a Function or Department</div>
            <div className={s.divText}>
              Select a{" "}
              <span style={{ color: "#E1242A" }}> function or department </span>
              in the left menu to view how it is structured.
            </div>
          </div>
        </div>
        <div className={s.descriptionText}>
          <div className={s.number}>
            <div className={s.circle}>2</div>
          </div>
          <div>
            <div className={s.subHeader}>Select a Role</div>
            <div className={s.divText}>
              Select a <span style={{ color: "#E1242A" }}> role </span>in the
              organization chart to view its details.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultView;
