import s from "./style.module.css";
import selectSite from "../../../assets/organizations/selectSite.svg";

const NoSiteSelected = () => {
  return (
    <div className={s.mainBlock}>
      <img className={s.image} src={selectSite} alt="Select Site" />
      <div className={s.descriptionText}>
        <p className={s.description}>Please Select a site to see the </p>
        <p className={s.description}>Organization Structure.</p>
      </div>
    </div>
  );
};

export default NoSiteSelected;
