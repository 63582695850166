import successStory from "../../assets/successStory/successStory.png";
import s from "./style.module.css";
import OtherStories from "./OtherStories";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../utilities/getFromApi";
import Loader from "../../utilities/Loader/Loader";
import ErrorPopup from "../../utilities/ErrorPopup/ErrorPopup";
import Story from "./Story";

const SuccessStories = () => {

  const [data, setData] = useState([]);
  const [allStories, setAllStories] = useState([]);
  const [currentStories, setCurrentStories] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [story, setStory] = useState(null);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios
      .post(`${baseURL}api/get_all_successStory`, {
        method: "POST",
      })
      .then((response) => {
        divideStories(response.data);
        setAllStories(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        setLoader(false);
      });
      return (setStory(null));
  }, []);

  const divideStories = (stories) => {
    let newArray = [];
    for (let i = 0; i < stories.length; i += 3) {
      newArray.push(stories.slice(i, i + 3));
    }
    setData(newArray);
  }

  const selectedStory = (newStory) => {
    story === null ? setIsExpanded(false) : setIsExpanded(true);
    let stories = allStories;
    stories = stories.filter((obj) => { return obj.story_id !== newStory.story_id });
    divideStories(stories);
    setCurrentStories(stories);
    setIsSelected(true);
    setStory(newStory);
    window.scrollTo(0, 0);
  }

  const resetLanding = () => {
    divideStories(allStories);
    setIsSelected(false);
    setIsExpanded(false);
    setStory(null);
  }

  return (
    <div>
      <Loader state={loader} />
      {!isSelected ?
        <div className={s.imageContainer}>
          <img src={successStory} alt="Success Story" />
          <div className={s.textPosition}>
            <p className={s.header}>Success Stories</p>
            <p className={s.subHeader}>Get inspired and learn more about stories of <span className={s.subPartText}>takeda</span> <br />
              employees who grew their career across different <br />
              functions and sites.</p>
          </div>
        </div>
        :
        <Story
          story={story}
          selectedStory={(story) => selectedStory(story)}
          resetLanding={() => resetLanding()}
        />
      }

      {data.length > 0 &&
        <OtherStories
          stories={data[0]}
          allStories={!isSelected ? allStories : currentStories}
          onSelectStory={(story) => selectedStory(story)}
          isExpanded={isExpanded}
          onChangeExpansion={() => setIsExpanded(!isExpanded)}
        />
      }

      {error !== null &&
        <ErrorPopup error={error} closeBtn={() => setError(null)} />
      }
    </div>
  );
};
export default SuccessStories;
