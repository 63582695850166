import { combineReducers } from "redux";
import {
  storeOperatingUnitData,
  storeSiteData,
  storeUserActiveSiteSelected,
  storeUserCurrentRoleSelected,
  storeUserAspiredRoleSelected,
  storeUserCurrentSelectedFunction,
  storeFullViewFlagFunction,
  storeCurrentRoleList,
  storeRoleSelectedFunction,
  storeOrgSelectedFunction,
  storeOrgSubSelectedFunction,
  storeOrgSelectedHeadFunction,
  storeOrgSideMenuFunction,
} from "./reducer";
const rootReducer = combineReducers({
  storeOperatingUnitData,
  storeSiteData,
  storeUserActiveSiteSelected,
  storeUserCurrentRoleSelected,
  storeUserAspiredRoleSelected,
  storeUserCurrentSelectedFunction,
  storeFullViewFlagFunction,
  storeCurrentRoleList,
  storeRoleSelectedFunction,
  storeOrgSelectedFunction,
  storeOrgSubSelectedFunction,
  storeOrgSelectedHeadFunction,
  storeOrgSideMenuFunction,
});

export default rootReducer;
