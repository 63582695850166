import React from "react";
import s from "./style.module.css";
const ErrorPopup = ({ error, closeBtn }) => {
  return (
    <div>
      {error && (
        <div className={s.loaderOverlay}>
          <div className={s.error}>
            <label>
              The following error encountered : {error.message}. Please refresh
              the page.
            </label>
            <br />
            <button className={s.closeBtn} onClick={() => closeBtn()}>
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ErrorPopup;
