import s from "./style.module.css";
import selectedSiteImg from "../../../../assets/careerPathways/selectedSite.svg";

const DefaultView = () => {
  return (
    <div className={s.mainBlock}>
      <img className={s.image} src={selectedSiteImg} alt="Select Site" />
      <div className={s.description}>
        <p className={s.descriptionHeader}>
          Here you can view possible career pathways.
        </p>
        <div className={s.descriptionText}>
          <div className={s.number}>
            <div className={s.circle}>1</div>
          </div>
          <div>
            <div className={s.subHeader}>Select Current Role</div>
            <div className={s.divText}>
              Select <span style={{ color: "#E1242A" }}> current role </span>
              to view{" "}
              <span style={{ color: "#E1242A" }}>
                {" "}
                all possible next moves{" "}
              </span>{" "}
              from a role.
            </div>
          </div>
        </div>
        <div className={s.descriptionText}>
          <div className={s.number}>
            <div className={s.circle}>2</div>
          </div>
          <div>
            <div className={s.subHeader}>Select Aspired Role (optional)</div>
            <div className={s.divText}>
              Select <span style={{ color: "#E1242A" }}>current role </span> and{" "}
              <span style={{ color: "#E1242A" }}>aspired role </span> to view
              the <span style={{ color: "#E1242A" }}>most direct pathways</span>{" "}<br/>
              from the current role to the aspired role.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultView;
