/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { VectorMap } from "@react-jvectormap/core";
import "./worldmap.css";
import { worldMill } from "@react-jvectormap/world";
import s from "./style.module.css";
import active from "./flags/active.png";
import comingsoon from "./flags/comingsoon.png";
const WorldMap = ({ siteSelectionOptions }) => {
  const [isShowMap, setIsShowMap] = useState(true);
  const [markerData, setmarkerData] = useState([]);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const mapRef = useRef(null);

  const showHoveredMarkerSite = (event, label, code) => {
    label.html(`
    <div class="hoverTooltip">    
    <div class="hoverContent">
    <img src=${markerData[code].siteImageUrl} class="hoverSiteImage"  alt={${markerData[code].siteName}} />
    </div>
    <label class="hoverlabel">
    <img src=${markerData[code].countryFlag} class="hoverFlag" alt={${markerData[code].country}}/>
     ${markerData[code].siteName} <label style="font-weight:400;">(${markerData[code].show})</label></label>
    </div>
    `);
  };

  useEffect(() => {
    setIsShowMap(false);
    setTimeout(() => {
      setIsShowMap(true);
    });
    const markers = siteSelectionOptions
      .filter((site) => site.label !== "Select Site")
      .map((place) => {
        return {
          latLng: [place.latitude, place.longitude],
          ...place,
        };
      });
    setmarkerData(markers);
    const observer = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setContainerSize({ width, height });
      if (mapRef.current) {
        mapRef.current.updateSize();
      }
    });
    observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [siteSelectionOptions]);
  return (
    <div className={s.container} ref={containerRef}>
      {isShowMap && (
        <VectorMap
          map={worldMill}
          containerStyle={{
            width: containerSize.width ? containerSize.width : "inherit",
            height: containerSize.height ? containerSize.height : "inherit",
          }}
          mapRef={mapRef}
          markers={markerData}
          series={{
            markers: [
              {
                attribute: "image",
                scale: {
                  Active: active,
                  Comingsoon: comingsoon,
                },
                values: markerData.reduce(function (p, c, i) {
                  p[i] = c.status;
                  return p;
                }, {}),
              },
            ],
          }}
          // className={s.customMapClass}

          backgroundColor="#FFFFFF"
          regionStyle={{
            initial: {
              fill: "#DDDDDD",
              stroke: "#DDDDDD",
              "stroke-width": 2,
              "stroke-opacity": 1,
            },
            hover: {
              fill: "#DDDDDD",
              stroke: "#DDDDDD",
              opacity: 1,
              "stroke-width": 2,
              "stroke-opacity": 1,
            },
          }}
          onMarkerTipShow={showHoveredMarkerSite}
          onRegionTipShow={(e, label, code) => {
            e.preventDefault();
          }}
          updateSize={(e) => {
            console.log(e);
          }}
          labels={{
            regions: {
              render: () => {},
            },
            markers: {
              render: (index) => {
                return markerData[index].siteName;
              },
              offsets: (index) => {
                return index % 2 === 0 ? [0 - 27, 0 + 15] : [0 - 27, 0 - 15];
                // return [0 - 27, 0 + 15];
              },
            },
          }}
        ></VectorMap>
      )}
    </div>
  );
};
export default WorldMap;
