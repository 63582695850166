import React, { useEffect, useState } from "react";
import s from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import WorldMap from "./WorldMap";
import { MultiSelect } from "react-multi-select-component";
import { baseURL } from "../../../utilities/getFromApi";
import axios from "axios";
import Select from "react-select";
import Loader from "../../../utilities/Loader/Loader";
import ErrorPopup from "../../../utilities/ErrorPopup/ErrorPopup";
import {
  updateOperatingUnitData,
  updateSelectedSite,
  updateSiteData,
} from "../../../store/actions";
const Arrow = () => (
  <div className={s.icon}>
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 1L7 7L1 0.999999" stroke="black" />
    </svg>
  </div>
);

const SelectSite = ({ operatingUnitData, siteData, getlandingPage }) => {
  const storeData = useSelector((state) => state);
  const dispatch = useDispatch();
  const [unitSelected, setUnitSelected] = useState(operatingUnitData);
  const [siteSelected, setSiteSelected] = useState([
    { label: "Select Site", value: "*", status: "Active" },
  ]);
  const [error, setError] = useState(null);
  const [modal, isModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [siteSelectionOptions, setSiteSelectionOptions] = useState([]);

  const [shownSite, setShownSite] = useState(
    storeData.storeUserActiveSiteSelect
  );
  const handleSiteChange = (e) => {
    setSiteSelected(e);
    if (e.label !== "Select Site") {
      const selectedSite = siteSelectionOptions.find(
        (site) => site.siteName === e.label
      );
      setShownSite(selectedSite);
      if (selectedSite.show === "Active") {
        dispatch(updateSelectedSite(selectedSite));
      } else {
        dispatch(
          updateSelectedSite({
            label: "Select Site",
            value: "*",
            status: "Active",
          })
        );
      }
    } else {
      setShownSite({});
      dispatch(
        updateSelectedSite({
          label: "Select Site",
          value: "*",
          status: "Active",
        })
      );
    }
  };
  const handleUnitChange = (e) => {
    let selOptions = e.map((op) => {
      return {
        label: op.value,
        value: op.value,
        show: op.show,
      };
    });
    setUnitSelected(selOptions);
    dispatch(updateOperatingUnitData(selOptions));
    const body = e.map((unit) => unit.value);
    setSiteSelectionOptions([]);
    setLoader(true);
    axios
      .post(
        `${baseURL}api/get_site_name`,
        { selected_ou_names: body },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        return res;
      })
      .then((data) => {
        const siteNameData = data.data.map((val) => {
          return {
            label: val.siteName,
            value: val.siteName,
            status: val.show === "Active" ? "Active" : "Comingsoon",
            stateName: val.state === "EMPTY" ? ", " : `, ${val.state}, `,
            ...val,
          };
        });
        setSiteSelectionOptions([
          { label: "Select Site", value: "*", status: "Active" },
          ...siteNameData,
        ]);
        dispatch(
          updateSiteData([
            { label: "Select Site", value: "*", status: "Active" },
            ...siteNameData,
          ])
        );
        setSiteSelected({ label: "Select Site", value: "*", status: "Active" });
        setShownSite({});
        setLoader(false);
      })
      .catch((error) => {
        setError(error);
        isModalOpen(true);
        setLoader(false);
      });
  };

  const formattedLabel = (option) => {
    if (option.status === "Active") {
      return <label>{option.label}</label>;
    } else {
      return (
        <label>
          {option.label}
          <label
            style={{
              color: "#898989",
            }}
          >
            &nbsp; ({option.show})
          </label>
        </label>
      );
    }
  };
  const customStyles = {
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        transition: "background-color 0.3s,color 0.3s",
        padding: "9px",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
          padding: "9px",
        },
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected ? "black" : "white",
        "&:hover": {
          backgroundColor: state.isSelected ? "black" : "#efefef",
          cursor: "pointer",
        },
      };
    },
    indicatorSeparator: (base) => ({
      ...base,
      marginTop: "0px",
      marginBottom: "0px",
    }),
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
    }),
  };

  const formattedOptions = (options) => {
    let arrayData =
      options &&
      options.length > 0 &&
      options.map((option) => ({
        ...option,
        label:
          option.show === "Active" ? (
            <label>{option.label}</label>
          ) : (
            <label>
              {option.label}
              <label
                style={{
                  color: "#898989",
                }}
              >
                &nbsp; ({option.show})
              </label>
            </label>
          ),
      }));
    return arrayData;
  };

  useEffect(() => {
    const opData = storeData.storeOperatingUnitData;
    const siteList = storeData.storeSiteData;
    if (opData && opData.length > 0) {
      setUnitSelected(opData);
    }
    if (siteList && siteList.length > 0) {
      setSiteSelectionOptions(siteList);
    }
    if (storeData && storeData.storeUserActiveSiteSelected.show) {
      setSiteSelected([storeData.storeUserActiveSiteSelected]);
      setShownSite(storeData.storeUserActiveSiteSelected);
    }
  }, [storeData]);

  return (
    <React.Fragment>
      <Loader state={loader} />
      {modal && (
        <ErrorPopup error={error} closeBtn={() => isModalOpen(false)} />
      )}
      <div className={s.siteSection}>
        <div className={s.siteheader}>Select Site</div>
        <div className={s.siteNote}>
          <div>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
                fill="#9B72B0"
              />
            </svg>
          </div>
          <div className={s.note}>
            Select a site that is active in Career Pathways to get Started
            Exploring Career Pathways.
          </div>
        </div>
        <div className={s.selectSite}>
          <div className={s.siteDropdown}>
            <div className={s.label}>Select Operating Unit/Region</div>
            <div className={s.wrap}>
              <MultiSelect
                options={formattedOptions(operatingUnitData)}
                ArrowRenderer={Arrow}
                value={unitSelected}
                onChange={(val) => {
                  handleUnitChange(val);
                }}
                disableSearch
                hasSelectAll={false}
                className={s.select}
                labelledBy={"Operating Unit"}
                ClearSelectedIcon={null}
                overrideStrings={{
                  selectSomeItems: `None selected`,
                  allItemsAreSelected:
                    unitSelected && unitSelected.length
                      ? `All Operating Units`
                      : `All`,
                }}
              />
            </div>
          </div>
          <div className={s.siteDropdown}>
            <div className={s.label}>Select Site</div>
            <div className={s.sitewrap}>
              <Select
                options={siteSelectionOptions}
                title={"Site list"}
                className={s.dropdownWrap}
                name={"name"}
                onChange={(val) => {
                  handleSiteChange(val);
                }}
                menuShouldScrollIntoView={true}
                getOptionLabel={formattedLabel}
                isSearchable={false}
                styles={customStyles}
                allowSelectAll={false}
                value={siteSelected}
              />
            </div>
          </div>
          <div className={s.maplegend}>
            <div className={s.maplegendlabel}>
              <div className={s.circle}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.46343"
                    cy="7.46343"
                    r="6.96343"
                    fill="white"
                    stroke="black"
                  />
                  <circle
                    cx="3.73171"
                    cy="3.73171"
                    r="3.73171"
                    transform="matrix(-1 0 0 1 11.1953 3.72998)"
                    fill="#E1242A"
                  />
                </svg>
              </div>
              Active in Career Pathways
            </div>
            <div className={s.maplegendlabel}>
              <div className={s.circle}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="7.46343"
                    cy="7.46343"
                    r="6.96343"
                    fill="white"
                    stroke="black"
                  />
                  <circle
                    cx="3.73171"
                    cy="3.73171"
                    r="3.73171"
                    transform="matrix(-1 0 0 1 11.1953 3.72998)"
                    fill="black"
                  />
                </svg>
              </div>
              Coming Soon to Career Pathways
            </div>
          </div>
        </div>
        <div className={s.mapArea}>
          {shownSite && shownSite.siteName && (
            <div className={s.siteDetails}>
              {shownSite.show === "Active" ? (
                <button
                  className={s.siteInfoButton}
                  onClick={() => {
                    getlandingPage(2);
                  }}
                >
                  Explore Pathways at this site
                </button>
              ) : (
                <button
                  className={s.siteInfoButton}
                  style={{
                    display: "flex",
                    backgroundColor: "#9B72B033",
                    color: "black",
                    textAlign: "left",
                    border: "1px solid black",
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM13 11V12V18V19H11V18V12V11H13ZM11 7V8H13V7V6V5H11V6V7Z"
                      fill="#9B72B0"
                    />
                  </svg>
                  This site is Coming Soon to Career Pathways.
                </button>
              )}
              <div className={s.siteInfoLabel}>
                {" "}
                <div className={s.siteInfoIcon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.1122 2.34866L5.58546 15.6H2.4V1.9612L5.1122 2.34866Z"
                      fill="white"
                      stroke="black"
                      stroke-width="0.8"
                    />
                    <path
                      d="M10.3631 11.8104L10.5222 11.9397L10.72 11.8861L17.6 10.0227V17.6H2.4V11.2808L7.46168 9.45301L10.3631 11.8104Z"
                      fill="white"
                      stroke="black"
                      stroke-width="0.8"
                    />
                    <line
                      x1="2"
                      y1="5.6"
                      x2="5"
                      y2="5.6"
                      stroke="black"
                      stroke-width="0.8"
                    />
                    <rect x="5" y="14" width="2" height="2" fill="black" />
                    <rect x="9" y="14" width="2" height="2" fill="black" />
                    <rect x="13" y="14" width="2" height="2" fill="black" />
                  </svg>
                </div>
                <div>{shownSite.siteName}</div>
              </div>
              <div className={s.siteInfoLabel}>
                <div className={s.siteInfoIcon}>
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 0C3.15415 0 0 3.04348 0 6.75099C0 9.71146 4.2332 14.6087 6.05929 16.6008C6.3083 16.8775 6.64032 17.0158 7 17.0158C7.35968 17.0158 7.6917 16.8775 7.94071 16.6008C9.7668 14.6364 14 9.71146 14 6.75099C14 3.01581 10.8458 0 7 0ZM7.22134 15.9368C7.083 16.0751 6.88933 16.0751 6.77866 15.9368C5.42292 14.4704 0.968379 9.43478 0.968379 6.75099C0.968379 3.5415 3.67984 0.968379 7 0.968379C10.3202 0.968379 13.0316 3.56917 13.0316 6.75099C13.0316 9.43478 8.57708 14.4427 7.22134 15.9368Z"
                      fill="black"
                    />
                    <path
                      d="M6.99996 3.98413C5.36755 3.98413 4.01182 5.31219 4.01182 6.97227C4.01182 8.60468 5.33988 9.96042 6.99996 9.96042C8.66004 9.96042 9.9881 8.63235 9.9881 6.97227C9.9881 5.31219 8.63237 3.98413 6.99996 3.98413ZM6.99996 8.96437C5.89324 8.96437 4.9802 8.05132 4.9802 6.94461C4.9802 5.83789 5.89324 4.92484 6.99996 4.92484C8.10668 4.92484 9.01972 5.83789 9.01972 6.94461C9.01972 8.05132 8.10668 8.96437 6.99996 8.96437Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div>{`${shownSite.city}${shownSite.stateName}${shownSite.country}`}</div>
              </div>
              <div className={s.siteInfoLabel}>
                <div className={s.siteInfoIcon}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3.33333"
                      y="5"
                      width="13.3333"
                      height="10"
                      rx="1.66667"
                      stroke="black"
                      stroke-width="0.833333"
                    />
                    <path
                      d="M3.33333 7.5L9.25464 10.4607C9.72386 10.6953 10.2761 10.6953 10.7454 10.4607L16.6667 7.5"
                      stroke="black"
                      stroke-width="0.833333"
                    />
                  </svg>
                </div>
                <div>{shownSite.siteHrEmail}</div>
              </div>
              <div className={s.siteInfoLabel}>
                <div className={s.siteInfoIcon}>
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3.51"
                      y="3.51"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="7.41008"
                      y="3.51"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="3.51"
                      y="6.76"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="7.41008"
                      y="6.76"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="3.51"
                      y="10.01"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="7.41008"
                      y="10.01"
                      width="2.08"
                      height="2.08"
                      fill="white"
                      stroke="black"
                      stroke-width="0.52"
                    />
                    <rect
                      x="0.1625"
                      y="0.812402"
                      width="12.675"
                      height="0.325"
                      fill="#E1242A"
                      stroke="black"
                      stroke-width="0.325"
                    />
                    <rect
                      x="1.62515"
                      y="1.62505"
                      width="9.75"
                      height="11.7"
                      stroke="black"
                      stroke-width="0.65"
                    />
                    <rect
                      x="3.4125"
                      y="0.1625"
                      width="6.175"
                      height="0.325"
                      fill="#E1242A"
                      stroke="black"
                      stroke-width="0.325"
                    />
                  </svg>
                </div>
                <div>{shownSite.operatingUnit.operatingUnitName}</div>
              </div>
              <div className={s.siteInfoLabel}>
                <img
                  className={s.siteImage}
                  src={shownSite.siteImageUrl}
                  alt={shownSite.siteName}
                />
              </div>
            </div>
          )}
          <div className={s.mapData}>
            <WorldMap
              siteSelectionOptions={
                shownSite && shownSite.siteName
                  ? [shownSite]
                  : siteSelectionOptions
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SelectSite;
