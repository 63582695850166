import React, { useEffect, useState } from "react";
import s from "./style.module.css";
import axios from "axios";
import { baseURL } from "../../../utilities/getFromApi";
import Loader from "../../../utilities/Loader/Loader";
import ErrorPopup from "../../../utilities/ErrorPopup/ErrorPopup";

const FAQ = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios
    .get(`${baseURL}api/get_all_faqs`, {
      method: "GET",
    })
    .then((response) => {
      setData(response.data);
      setLoader(false);
    })
    .catch((error) => {
      setError(error);
      setLoader(false);
    });

    // setData(locaData);

    // setData([
    //   {
    //     question: "What is the difference between Career Pathways and Career Navigator?",
    //     answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    //   },
    //   {
    //     question: "How can Career Pathways help me in my career development plan?",
    //     answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    //   }
    // ]);
  }, []);

  return (
    <React.Fragment>
      <Loader state={loader} />
      <div className={s.mainContainer}>
        <div>
          {data.length > 0 && data.map((list, index) => {
            return (
              <div key={index} className={`${s.col} ${index !== (data.length -1) && s.bottomSpace}`}>
                <p className={s.question}>{list.question}</p>
                <p className={s.answer}>{list.answer}</p>
              </div>
            )
          })}
        </div>
        {error !== null && 
          <ErrorPopup error={error} closeBtn={() => setError(null)} />
        }
      </div>
    </React.Fragment>
  );
};
export default FAQ;
