import React from "react";
import s from "./style.module.css";
const Loader = ({ state }) => {
  return (
    <div>
      {state && (
        <div className={s.loaderOverlay}>
          <div className={s.loader}></div>
        </div>
      )}
    </div>
  );
};
export default Loader;
