
import { useEffect, useState } from "react";
import { RolePathChart } from "./RolePathChart";

const RolePath = ({
  width = 1100,
  height = 600,
  paths,
  handleRolePage,
}) => {
  const [axis, setAxis] = useState([]);

  useEffect(() => {
    let array = [];
    for (let i = 0; i < paths.length; i++) {
      array = array.concat(paths[i].path_elements);
    }
    // array = array.sort((a,b) => (a.career_band > b.career_band) ? 1 : (b.career_band > a.career_band) ? -1 : 0);
    
    const uniqlabel = [
      ...new Map(
        array.map((item) => [item['business_title'], item])
      ).values(),
    ];
    setAxis(uniqlabel);
  }, [paths]);

  return ( 
    <RolePathChart
      data={axis}
      paths={paths}
      width={width}
      height={height}
      handleRolePage={handleRolePage}
    />
  )
};

export default RolePath;
