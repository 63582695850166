import s from "./style.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateRoleSelected } from "../../../../../store/actions";
const RoleFullViewPopup = ({
  selectedFunciton,
  handleFullView,
  handleRolePage,
}) => {
  const CollapsibleRoles = ({ dataList, ind }) => {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    return (
      <div className={s.stack}>
        {dataList.departmentName !== "Function Head" && (
          <div className={s.stackhead}>
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <div
                className={s.stackRolecount}
                style={{ backgroundColor: `${selectedFunciton.colorCode}66` }}
              >
                {dataList.roles.length}
              </div>
              <div>{dataList.departmentName}</div>
            </div>
            <div onClick={() => setOpen(!open)}>
              {open ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.11188L12 18.1119"
                    stroke="white"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18 12.1119L6 12.1119"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 6.22266L12 18.2227"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                  <path
                    d="M18 12.2227L6 12.2227"
                    stroke="#4C4948"
                    stroke-linecap="round"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
        {open && (
          <>
            <div className={s.stackbodyHead}></div>
            <div
              className={s.stackbody}
              key={`${ind}${dataList.departmentName}`}
            >
              {dataList.roles.map((role, id) => {
                return (
                  <div
                    className={s.roletag}
                    key={`${dataList}${id}${role.aspiredRoleName}`}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      {role.upwardLateral === "Upward" ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            fill="white"
                          />
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            fill="#ABB436"
                            fill-opacity="0.6"
                          />
                          <path
                            d="M2.20577 14.5L10 1L17.7942 14.5H2.20577Z"
                            stroke="black"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            fill="#E1242A"
                          />
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            fill="white"
                            fill-opacity="0.4"
                          />
                          <path
                            d="M1.06641 10.0664L7.43164 3.56641L7.43164 6.93164L12.9316 6.93164V3.93164L18.0664 10.0664L12.9316 16.4316V12.9316H7.43164V16.4316L1.06641 10.0664Z"
                            stroke="black"
                          />
                        </svg>
                      )}
                      <div
                        className={s.selectRole}
                        onClick={() => {
                          handleRolePage(true);
                          dispatch(updateRoleSelected(role.aspiredRoleName));
                        }}
                      >
                        {role.aspiredRoleName}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <div className={s.popupOverlay}>
      <div className={s.container}>
        <div className={s.mainBlock}>
          <div
            className={s.tabLook}
            style={{ backgroundColor: `${selectedFunciton.colorCode}33` }}
          ></div>
          <div
            className={s.body}
            style={{ backgroundColor: `${selectedFunciton.colorCode}33` }}
          >
            <div
              className={s.smallViewBtn}
              onClick={() => handleFullView(false)}
            >
              Short View{" "}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 15V14.5H14.5V15H15ZM20.6464 21.3536C20.8417 21.5488 21.1583 21.5488 21.3536 21.3536C21.5488 21.1583 21.5488 20.8417 21.3536 20.6464L20.6464 21.3536ZM15 9H14.5V9.5H15V9ZM21.3536 3.35355C21.5488 3.15829 21.5488 2.84171 21.3536 2.64645C21.1583 2.45118 20.8417 2.45118 20.6464 2.64645L21.3536 3.35355ZM9 15H9.5V14.5H9V15ZM2.64645 20.6464C2.45118 20.8417 2.45118 21.1583 2.64645 21.3536C2.84171 21.5488 3.15829 21.5488 3.35355 21.3536L2.64645 20.6464ZM9 9V9.5H9.5V9H9ZM3.35355 2.64645C3.15829 2.45118 2.84171 2.45118 2.64645 2.64645C2.45118 2.84171 2.45118 3.15829 2.64645 3.35355L3.35355 2.64645ZM15.5 20V15H14.5V20H15.5ZM15 15.5H20V14.5H15V15.5ZM14.6464 15.3536L20.6464 21.3536L21.3536 20.6464L15.3536 14.6464L14.6464 15.3536ZM14.5 4V9H15.5V4H14.5ZM15 9.5H20V8.5H15V9.5ZM15.3536 9.35355L21.3536 3.35355L20.6464 2.64645L14.6464 8.64645L15.3536 9.35355ZM9.5 20V15H8.5V20H9.5ZM9 14.5H4V15.5H9V14.5ZM8.64645 14.6464L2.64645 20.6464L3.35355 21.3536L9.35355 15.3536L8.64645 14.6464ZM8.5 4V9H9.5V4H8.5ZM9 8.5H4V9.5H9V8.5ZM9.35355 8.64645L3.35355 2.64645L2.64645 3.35355L8.64645 9.35355L9.35355 8.64645Z"
                  fill="#4C4948"
                />
              </svg>
            </div>
            <div
              className={s.splitter}
              style={{
                border: `1px solid ${selectedFunciton.colorCode}`,
              }}
            ></div>
            <div className={s.cards}>
              {selectedFunciton &&
                selectedFunciton.departments.map((sfdata, index) => {
                  return <CollapsibleRoles dataList={sfdata} ind={index} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoleFullViewPopup;
