import s from "./style.module.css";
import { Timeline } from 'rsuite';
// import 'rsuite/dist/rsuite.min.css';
import "rsuite/dist/rsuite-no-reset.min.css";
import Location from "../../../assets/successStory/location.svg";
import Flag from "../../../assets/successStory/flag.svg";
import Rectangle from "../../../assets/successStory/rectangle.svg";

const OtherStories = ({ stories, allStories, onSelectStory, isExpanded, onChangeExpansion }) => {

  const displayStories = (stories) => {
    return (
      <div className={isExpanded ? s.container1 : s.container}>
        {stories.map((story, index) => {
          return (
            <div className={isExpanded ? s.storyContent1 : s.storyContent} key={index} onClick={() => onSelectStory(story)}>
              <div className={s.profile}>
                {story.profile_picture &&
                  <img className={s.profilePicture} src={story.profile_picture} alt="profile_picture" />
                }
                <div className={s.profileDetails}>
                  <p className={s.eventName}>{story.employee_name}</p>
                  <p className={s.function}>Tenure: {story.tenure}</p>
                </div>
              </div>

              <hr className={s.divider} />

              <Timeline className="custom-timeline">
                <Timeline.Item dot={<img src={Location} alt="Location" />}>
                  <p className={s.eventName}>{story.first_role.event_name}</p>
                  <p className={s.function}>{story.first_role.function}</p>
                  <p className={s.site}>{story.first_role.site}</p>
                </Timeline.Item>
                <Timeline.Item dot={<img src={Flag} alt="Flag" />}>
                  <p className={s.eventName}>{story.current_role.event_name}</p>
                  <p className={s.function}>{story.current_role.function}</p>
                  <p className={s.site}>{story.current_role.site}</p>
                </Timeline.Item>
              </Timeline>
              {story.quote !== "EMPTY" &&
                <div>
                  <img className={s.rectangleImg} src={Rectangle} alt="Rectangle" />
                  <blockquote className={s.quote}>
                    {story.quote}
                  </blockquote>
                </div>
              }
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className={s.mainContainer}>
      <div className={isExpanded ? s.successStories1 : s.successStories}>
        <p className={s.header}>Success Stories</p>
        <p className={s.subText} onClick={() => {
          onChangeExpansion();
          !isExpanded === true && window.scrollBy(0, 450);
        }}>{!isExpanded ? "See More" : "See Less"}</p>
      </div>

      {isExpanded ? displayStories(allStories) : displayStories(stories)}
    </div>
  );
};
export default OtherStories;
